<div class="legal-notice">
    <div class="row">
        <div class="offset-xl-2 offset-lg-0 offset-md-0 offset-sm-0 col-lg-6 col-xl-6 col-md-9 col-sm-8 col-xs-12">
            <header class="header1 f-60">Legal notice</header>
            <p class="h"> </p>
            <p class="black f-18">Legal terms of use</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>This website (“Website”) is provided to you under these “Terms of Use” and any amendments or
                supplements to them (collectively referred to as this “Agreement”) that may be posted by Medical
                Devices Business Services, Inc. or one of its affiliated companies (collectively, “Site Owner,”
                “we”, “us”) from time to time. Your use of this Website, or any other services or content provided
                through the Website [or Mobile Software], shall be deemed to constitute your consent to be legally
                bound by the terms and conditions of the Agreement, which shall be enforceable in the same way as if
                you had signed the Agreement. If you are not willing to accept the terms and conditions in the
                Agreement, we ask that you not access or use the Website or post or submit any materials on it.</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="black f-16">Website Intended Audience</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>This Website is intended for and directed to residents of the United States. This Website is not
                intended for anyone under the age of 18.</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="black f-18">Information disclaimer</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>THE INFORMATION INCLUDING ANY, ADVICE AND RECOMMENDATIONS ON THE WEBSITE IS INTENDED SOLELY AS A
                GENERAL EDUCATIONAL AID. IT IS NOT INTENDED AS MEDICAL OR HEALTHCARE ADVICE, OR TO BE USED FOR
                MEDICAL DIAGNOSIS OR TREATMENT, FOR ANY INDIVIDUAL PROBLEM. IT IS ALSO NOT INTENDED AS A SUBSTITUTE
                FOR PROFESSIONAL ADVICE AND SERVICES FROM A QUALIFIED HEALTHCARE PROVIDER FAMILIAR WITH YOUR UNIQUE
                FACTS. ALWAYS SEEK THE ADVICE OF YOUR DOCTOR OR OTHER QUALIFIED HEALTHCARE PROVIDER REGARDING ANY
                MEDICAL CONDITION AND BEFORE STARTING ANY NEW TREATMENT. YOUR USE OF THE SITE IS SUBJECT TO THE
                ADDITIONAL DISCLAIMERS AND CAVEATS THAT MAY APPEAR THROUGHOUT THE WEBSITE.</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>WE ASSUME NO RESPONSIBILITY FOR ANY CONSEQUENCE RELATING DIRECTLY OR INDIRECTLY TO ANY ACTION OR
                INACTION YOU TAKE BASED ON THE INFORMATION, SERVICES, OR OTHER MATERIAL ON THE WEBSITE. WHILE WE
                STRIVE TO KEEP THE INFORMATION ON THE WEBSITE ACCURATE, COMPLETE, AND UP-TO-DATE, WE DO NOT GIVE ANY
                ASSURANCES, AND WILL NOT BE RESPONSIBLE FOR, ANY DAMAGE OR LOSS RELATED TO THE ACCURACY,
                COMPLETENESS, OR TIMELINESS OF THE INFORMATION ON THE WEBSITE.</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="black f-18">Ownership</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>You understand that the Website is available for your personal, non-commercial use only. You agree
                that the Website is the property of the Site Owner; including all intellectual property rights in it
                and that you have no right to use them other than as set out in these Terms of Use. We are not
                responsible for any harm or loss that you suffer in relation to any use you make of the Website for
                any business purposes or other purposes not authorized under these Terms of Use. We reserve the
                right to refuse or terminate access to the Website at our discretion. The Website are provided free
                of charge and on that basis we have no obligation to provide any maintenance or support services in
                relation to them and we are not responsible for any loss or damage you may suffer as a result of any
                failure to maintain or update the Website.</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>You may not copy, change or reuse the Website, any updates to them or any part of them including the
                software incorporated in them.</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>You may use this Website only for lawful purposes and in accordance with these Terms of Use. You are
                granted a nonexclusive, nontransferable, revocable, limited license to view, print and distribute
                content retrieved from the Website for your personal, noncommercial purposes, provided that you do
                not remove or obscure the copyright notice or other notices displayed on the content. You may not
                copy, reprint, modify, display, perform, translate, distribute, adapt, broadcast, communicate to the
                public by telecommunication, circulate, or sell the content retrieved from the Website in any way,
                for any commercial use or provide it to any commercial source, including other websites, without the
                prior written permission of Site Owner.</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>In addition, you agree not to: (i) use this Website in any manner that could disable, overburden,
                damage, or impair this Website, or interfere with any other use of this Website, including, any
                user’s ability to engage in real-time activities through this Website; (ii) use any robot, spider or
                other automatic device, process or means to access this Website for any purpose, including to
                scrape, data mine, monitor or copy any of the material on this Website; (iii) use any manual process
                to monitor or copy any of the material on this Website, or to engage in any other unauthorized
                purpose without the express prior written consent of Site Owner; (iv) otherwise use any device,
                software or routine that interferes with the proper working of this Website; or (v) otherwise
                attempt to interfere with the proper working of this Website.</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>We reserve all rights not expressly granted to you.</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="black f-18">Your account and password</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>When you first register to use the Website you may be prompted to create a password. You must keep
                your password secret and not give it to anyone else or let them use your account. You must not use
                anyone else’s password or account. You must inform us immediately if you suspect any unauthorized
                use of or access to your password or account. We will not be responsible if you suffer any harm or
                loss because you do not keep your password secret, if you let someone else use your account, if you
                use someone else’s password or account, or if you become aware of or suspect unauthorized use of
                your password or account but do not tell us promptly.</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="black f-18">Privacy and consent to use data</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>The information that we obtain through your use of the Website, whether through the registration
                process or otherwise, is subject to our Privacy Policy. Our Privacy Policy addresses our collection
                and use of the data you provide to us, including your rights relative to that information.</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="black f-18">Electronic communications</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>The information communicated on the Website constitutes an electronic communication. When you
                communicate with us through the Website or via other forms of electronic media, such as e-mail, you
                are communicating with us electronically. You agree that we may communicate electronically, subject
                to local privacy laws, and that such communications, as well as notices, disclosures, agreements,
                and other communications that we provide to you electronically, are equivalent to communications in
                writing and shall have the same force and effect as if they were in writing and signed by the party
                sending the communication.</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="black f-18">User submissions</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>We have created, or may create in the future, certain areas, such as message boards and chat rooms,
                within the Website where members can upload and share their thoughts, comments, product reviews,
                pictures, videos and the like with other members (collectively, “Forums”). These Forums may be
                available to registered members of the Website only. To help ensure that the text, graphics,
                software, music, sound, photographs, videos, messages, posts, data, information, or other materials
                posted by users (collectively, the "User Content") is beneficial for the greater community, we have
                established some basic rules for everyone to follow.</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>In addition, if a Forum is provided within the Website, it may allow users to post questions to be
                answered by experts engaged by us. Where applicable, these experts are paid by us for their time in
                responding to members’ questions, but the opinions they express are their own. We are providing
                access to the experts and their guidance in the Forum solely as an informational and educational
                service to members of the Website.</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>Our policy for this Website is to review User Content, as far as practical, either before or after
                such User Content is posted. Pre-moderation means that the User Content will not be posted to the
                Forum and cannot be accessed or viewed by other Website members until we have reviewed it and
                determined that the User Content is suitable for posting to the Forum. This process also means that
                User Content submitted for posting will not be posted in real time and may never be posted if we
                determine that it is unsuitable for the Website. Post-moderation means that User Content will be
                reviewed by us once it is already posted on the Website. Post-moderated User Content that Site Owner
                determines does not comply with these Terms or is otherwise unsuitable for the Website will be
                removed from the Website, without notice to the User.</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>User Content must be appropriate for the Forum. Without limiting the reasons for which User Content
                may be rejected by us, we may reject, edit or remove any User Content that does not comply with any
                Community Guidelines that we provide – or may provide in the future - , which you must read prior to
                posting any User Content on the Website.</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>By submitting User Content for posting at a Forum, you also (1) confirm that you are entitled to post
                or transmit User Content; (2) agree that you are solely responsible for all User Content that you
                submit for posting; and (3) grant to Site Owner and its affiliated entities an irrevocable,
                worldwide, nonexclusive, perpetual, fully sub-licensable, transferable, royalty-free right and
                license to use, your User Content in any way on the Website and on any other Website owned or
                operated by Site Owner or its affiliates (as well as on any social media sites associated with the
                Site Owner or its affiliates).</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>We are free to use any ideas, concepts, know-how, or techniques contained in or derived from any User
                Content for any purpose whatsoever, including developing, manufacturing, and marketing products.
                Therefore please do not submit or send to us any ideas, suggestion or materials that you wish to
                keep confidential or for which you expect to receive compensation. You will not have any claim
                against us with respect to any use or non-use of User Content, When you submit User Content, you
                understand that we will have the right, but not the obligation, to use, display and publish your
                name, photograph, likeness, voice, performance, biographical information and/or statements,
                throughout the world in perpetuity on the Website and on any affiliate or successor Website or
                social media channels owned or operated by us or our affiliates. If we make use of any of these
                rights, you understand and agree that you shall not receive any other consideration, payment,
                notification or credit, nor will you have any approval over how Site Owner uses them.</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>You acknowledge that the Forums contain the opinions and views of other users. You acknowledge
                further that we are not responsible for the accuracy of any User Content on the Website. You
                understand and agree that all User Content is the sole responsibility of the person who posted the
                Content. You understand and agree that you will evaluate, and bear all risks associated with, the
                use of any User Content, including any reliance on the accuracy, completeness, or usefulness of such
                User Content.</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>We reserve the right at any time to discontinue, temporarily or permanently, your ability to upload
                User Content and/or your ability to access User Content with or without notice, at our sole
                discretion. You agree that we shall not be liable to you or to any third party for any suspension or
                discontinuance of acceptance of User Content.</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>Remember that all User Content you submit to the Website through a mobile device will be stored on
                that mobile device. The security and safety of your mobile device, and the information and materials
                contained on it, as well as any password you use on your device and to access the Website are your
                sole responsibility.</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="black f-18">Storage of user content</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>We may impose a maximum amount of storage for User Content on the Website. We are not responsible for
                any loss or harm you may suffer as a result of any deletion or failure to store any messages,
                communications or other User Content associated with maintaining the maximum amount of storage.</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="black f-18">Additional terms</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>Additional terms may govern certain features or content on the Website, such as offers, prize draws,
                competitions, contests and sweepstakes. By participating in any activity on the Website governed by
                additional terms, such as a prize draw, competition, contest or sweepstakes with Official Rules, you
                agree that you will be subject to those additional terms in addition to these Terms of Use.</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>Disclaimer of Warranties With Respect to Use of Website</p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>THE WEBSITE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. EXCEPT AS SPECIFICALLY PROVIDED
                HEREIN, TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, SITE OWNER EXPRESSLY DISCLAIMS
                    ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, LEGAL OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY
                    WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p>SITE OWNER DOES NOT MAKE ANY WARRANTY THAT THE WEBSITE WILL MEET YOUR REQUIREMENTS, OR THAT ACCESS TO
                    THE WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, OR THAT DEFECTS, IF ANY, WILL BE
                    CORRECTED. SITE OWNER MAKES NO WARRANTIES AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
                    WEBSITE OR AS TO THE ACCURACY, QUALITY, OR RELIABILITY OF ANY INFORMATION OBTAINED THROUGH THE
                    WEBSITE.</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p>YOU UNDERSTAND AND AGREE THAT ANY MATERIAL AND/OR DATA DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE
                    USE OF THE WEBSITE IS USED AT YOUR OWN RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE
                    TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH MATERIAL AND/OR DATA.
                </p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p>NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM SITE OWNER OR THROUGH THE
                    WEBSITE SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p class="black f-18">Limitations of liability</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT UNDER NO CIRCUMSTANCES WILL SITE OWNER, ITS AFFILIATES AND
                    ANY OF THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, MANDATARIES OR OTHER REPRESENTATIVES
                    BE LIABLE FOR DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, PUNITIVE OR AGGRAVATED DAMAGES,
                    INCLUDING, WITHOUT LIMITATION, ANY LOSS OF USE, LOSS OF INCOME, SAVING OR PROFITS, LOSS OF DATA,
                    LOSS OF GOODWILL, COST OF PROCUREMENT OF SUBSTITUTE SERVICES, OR ANY OTHER INDIRECT, SPECIAL,
                    INCIDENTAL, OR CONSEQUENTIAL DAMAGES, HOWSOEVER CAUSED, AND ON ANY THEORY OF LIABILITY, WHETHER FOR
                    BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE AND STRICT LIABILITY), OR OTHERWISE RESULTING FROM
                    (1) THE USE OF, OR THE INABILITY TO USE, THE WEBSITE; (2) THE COST OF PROCUREMENT OF SUBSTITUTE
                    SERVICES, ITEMS, OR WEBSITES; (3) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA
                    (INCLUDING PERSONAL INFORMATION); (4) THE STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE WEBSITE;
                    OR (5) ANY OTHER MATTER RELATING TO THE WEBSITE. THESE LIMITATIONS WILL APPLY WHETHER OR NOT SITE
                    OWNER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND NOTWITHSTANDING ANY FAILURE OF
                    ESSENTIAL PURPOSE OF ANY LIMITED REMEDY. THIS EXCLUSION OF LIABILITY SHALL APPLY TO THE FULLEST
                    EXTENT PERMITTED BY APPLICABLE LAWS.</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p class="black f-18">Trademark notices</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p>The trademarks and brand names displayed on this Website are the property of Site Owner, its
                    affiliates or third party owners. You may not use or display any trademarks or service marks owned
                    by Site Owner without our prior written consent. You may not use or display any other trademarks or
                    service marks displayed on this Website without the permission of their owners.</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p class="black f-18">Notice and procedure for making claims of copyright infringement</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p>We ask our users to respect the intellectual property of others. Pursuant to the Digital Millennium
                    Copyright Act, 17 U.S.C. sec. 512, we have designated an agent to receive notices of claimed
                    copyright infringement who can be reached through the “Contact Us” section of this website if filled
                    out further to the process outlined in paragraphs 1-6 below, via the phone numbers listed on our
                    Contact Us page, and/or via a letter with the below six (6) pieces of information sent to the
                    following address:</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p>Medical Devices Business Services, Inc.</p>
                <p>Attention: Communications</p>
                <p>700 Orthopaedic Drive</p>
                <p>P.O. Box 988</p>
                <p>Warsaw, IN 46581</p>
                <p>(800) 473-3789</p>
                <p><span [innerHTML]="email"></span></p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p>If you believe in good faith that your work has been copied in a way that constitutes copyright
                    infringement, please provide our Copyright Agent the following information through the process
                    described below:</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <ul>
                    <li>
                        <p>A listing of the accurate First Name, Last Name, Email Address, and Physical Address of the person
                            authorized to act on behalf of the owner of the copyright interest;</p>
                    </li>
                    <p class="h"> </p>
                    <li>
                        <p>If using the “Contact Us” page, selection of the “Concern or Complaint” option under the “Subject of
                            Email” section of the form. A description of the copyrighted work that you claim has been infringed with an original signature.
                            If submitting via our “Contact Us” form, please provide this information in the “Question or
                            Comment” section of the “Contact Us” form, including an electronic signature at the end of your
                            description (e.g., /name/);</p>
                    </li>
                    <p class="h"> </p>
                    <li>
                        <p>A description of where the material that you claim is infringing is located on the Website (including
                            a specific link/URL where possible);</p>
                    </li>
                    <p class="h"> </p>
                    <li>
                        <p>A statement by you that you have a good faith belief that the disputed use is not authorized by the
                            copyright owner, its agent, or the law, to be included in the complaint, or if using the form, to be
                            included in the “Question or Comment” section of the “Contact Us” form; and</p>
                    </li>
                    <p class="h"> </p>
                    <li>
                        <p>A statement by you, made under penalty of perjury that the above information in your notice is
                            accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf,
                            to be included in the complaint letter, or if using the form, to be included in the “Question or
                            Comment” section of the “Contact Us” form (which will contain your “electronic signature” as
                            required in item three (3) above).</p>
                    </li>
                </ul>
                <p>Upon receipt of the notification containing all of the information as outlined in 1 through 6 above:
                </p>
                <p class="h"> </p>
                <p class="h"> </p>
                <ul>
                    <li>
                        <p>We shall remove or disable access to the material that is alleged to be infringing;</p>
                    </li>
                    <p class="h"> </p>
                    <li>
                        <p>We shall forward the notification to such alleged infringer ("Subscriber"); and</p>
                    </li>
                    <p class="h"> </p>
                    <li>
                        <p>We shall take reasonable steps to promptly notify the Subscriber that it has removed or disabled
                    access to the material.</p>
                    </li>
                </ul>
                <p class="black f-18">Modification or Suspension of the Website</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p>We will make reasonable efforts to make the Website available but we may from time to time need to
                    interrupt, restrict, modify or discontinue, temporarily or permanently the Website or parts of it without notice. We will not be responsible for any harm or loss you may suffer as a result of such actions.</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p class="black f-18">Termination</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p>We may at any time terminate your use of or access to the Website and may delete any or all of your User Content without prior notice if we have a good reason to do so which includes any breach by you of these Terms of Use and the Community Guidelines. We will not be responsible for any loss or harm you may suffer as a result of termination of your use of the Website in these circumstances. In the event of any termination you must stop using the Website and you agree that the provisions of the Agreement regarding Ownership, Trademark Notices, Indemnification, Disclaimer or Warranties, Limitations of Liability, and Applicable Law shall survive any such termination.</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p class="black f-18">Links to third party sites</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p>This Website may contain links or references to third party websites. These links are provided for your convenience only. No endorsement of any third party products, services or information is expressed or implied by any information, material or content of any third party contained in, referred to, included on, or linked from or to this Website. Any information, data, opinions, recommendations, products or services provided by such third parties through links to other websites or otherwise made available through their websites are solely those of such third parties and not of Site Owner. Your use of such third party websites is subject to the terms of use and the privacy policies of such websites.</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p class="black f-18">Indemnification</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p>You agree to indemnify and hold harmless Site Owner and its affiliates and their respective directors, officers, employees, agents, or other representatives from and against all claims, liability, damages and expenses, including without limitation all legal fees and costs arising from or relating to (a) your breach of these Terms of Use; (b) your use of this Website including without limitation transmission or placement of information or material by you on this Website; and (c) any claim or allegation that any of your User Content infringes the intellectual property or other proprietary rights, or privacy rights, of any third party.</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p class="black f-18">Modifications to these terms</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p>We may make changes to the Terms of Use from time to time in our sole discretion, by updating these Terms of Use on this Website, and specifying the effective date of the new version of the Terms of Use. The “Last Modified” date at the top of these Terms of Use will indicate when the latest changes were made. Your continued use of the Website following the posting of a new version of the Terms of Use constitutes your acceptance of any such changes. Accordingly, whenever you visit this Website, you should check to see if a new version of the Agreement has been posted.</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p class="black f-18">Contact information</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p>If you have any questions or concerns with respect to these Terms of Use or the Website you may contact us as identified in the Privacy Policy or the “Contact Us” section of this Website.</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p class="black f-18">Severability of agreement</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p>Should any part or provision of these Terms of Use be held unlawful, void, invalid, or unenforceable that portion shall be deemed severable from these Terms of Use and shall not affect the validity and enforceability of any remaining provisions.</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p class="black f-18">Survival</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p>All Sections shall survive the termination of the right to use the Website.</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p class="black f-18">Applicable Law and Jurisdiction</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p>The information on the Website is intended only for use by residents of the United States. Other countries may have laws, regulatory requirements, or medical practices that differ from those in the United States. The Agreement and the resolution of any dispute related to the Agreement, the Website, or items you purchase through the Website, and any non-contractual obligations arising out of or in connection with these Terms of Use, shall be governed by and construed in accordance with the laws of the United States, without giving effect to any principles of conflicts of law. Any legal action or proceeding between Site Owner and you related to the Agreement shall be brought exclusively in a court of competent jurisdiction sitting in the United States, and you agree to submit to the personal and exclusive jurisdiction of such courts.</p>
                <p class="h"> </p>
                <p class="h"> </p>
                <p class="pad">Last updated April 2, 2018</p>
            </div>
        </div>
    </div>                