@if (activeHeader) {
  <div class="header">
    <div class="overlay" [ngClass]="{'activated': isPmMenuClicked == true}"></div>
    <nav class="navbar navbar-expand-custom">
      @if (!isOpsHeader) {
        <a class="navbar-brand text-left" (click)="navigateToUserHome();">
          @if (showLogo) {
            <img class="img-fluid logo" src="assets/img/AITA logo.svg" alt="AITA Logo" />
          }
        </a>
      }
      @if (isOpsHeader) {
        <a class="navbar-brand text-left" [routerLink]="'/ops'">
          <span>@if (showLogo) {
            <img class="img-fluid logo" src="assets/img/AITA logo.svg"
              alt="AITA Logo" />
          }</span>
          <span class="ops_logo_divider">&nbsp;</span>
          <span class="ops_team">OPS TEAM</span>
        </a>
      }
      <span class="search">
        @if (showLogo && !minimalHeader && !isOpsHeader) {
          <app-search-bar></app-search-bar>
        }
      </span>
      <div style="display: flex; justify-content: flex-end;">
        @if (authenticated) {
          <ul class="navbar-nav tablet-mode">
            @if (!isOpsHeader) {
              <li class="nav-item black mobile-list-item searchNav">
                <a class="nav-link" id="search-bar-link" (click)="toggleSearchNav()">
                  <em class="material-icons">search</em>
                </a>
              </li>
            }
            @if (!isOpsHeader) {
              <li class="nav-item notification-nav dropdown">
                <a class="nav-link" (click)="toggleNotificationNav($event)"
                  [ngClass]="{'selected':currentModule == 'notifications'}">
                  <em class="material-icons notification" [ngClass]="{'no-hover':notificationCount == 0 }"
                  [style.color]="notificationTrayExtended ? '#E02D00' : '#636681'">notifications</em>
                  <span class="badge medium f-13">{{notificationCount}}</span>
                </a>
                <div class="dropdown-menu notListDropdown"
                  [ngClass]="{ 'show': navbarOpen,'height-auto':notifications.length < 4  }"
                  aria-labelledby="navbarDropdownMenuLink"
                  [style.display]="notificationTrayExtended ? 'block' : 'none'">
                  <div class="row">
                    <div class="row f-14 black col-12 noteHead">
                      Notifications
                    </div>
                  </div>
                  <div class="row itemContainer notificationTrayBlock" #notificationMblTray>
                    @for (item of mapOfAlerts; track item) {
                      <div class="notificationElement">
                        <mat-accordion [togglePosition]="'before'">
                          <mat-expansion-panel (opened)="panelOpenState = true"
                            (closed)="panelOpenState = false">
                            <mat-expansion-panel-header class="matPanelHeader">
                              <mat-panel-title>
                                <div class="row" style="width: 100% !important;">
                                  <div class="f-14 black col-7 noteHead" style="padding: 14px 0px !important;">
                                    <div class="bold f-12 text-left contentMsg" style="margin-top: 3px;">
                                      <span>{{loadNotification(notificationCategories,
                                      returnGroupDetails(item.key,","))}}</span>
                                      <span
                                        class="badge small f-11 groupBadge">{{returnGroupCountDetails(item.key,",")}}
                                      </span>
                                    </div>
                                  </div>
                                  <div class="f-11 black col-5 noteHead dismissBlock">
                                    <div class="dismissButton">
                                      <span style="font-size: 10px !important;"
                                        (click)="dismissTrayGroupNotification(returnGroupDetails(item.key,','), returnGroupCountDetails(item.key,',') )">
                                        Dismiss All
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            @for (notification of item.value; track notification) {
                              <div class="row noteItem done">
                                <div class="noteImg text-center">
                                  @if (notification.isAlert) {
                                    <em
                                    class="material-icons-round warning">warning</em>
                                  }
                                </div>
                                <div class="noteContent">
                                  <div class="bold f-14 text-left contentMsg">
                                    {{notification.body.content.msg}}
                                  </div>
                                  @if (!(notification.notificationDate | customDate : 'isToday')) {
                                    <div class="regular f-12 text-grey"
                                      >
                                      {{notification.notificationDate | customDate : 'date'}}
                                    </div>
                                  }
                                  @if ((notification.notificationDate | customDate : 'isToday')) {
                                    <div class="regular f-12 text-grey"
                                      >
                                    {{notification.timeStamp}} ago</div>
                                  }
                                  <div class="noteAction text-left semibold f-14">
                                    <a (click)="navigateToNotificationFlow($event,notification.notificationId, notification.category, notification.body.action.params,notification.body.action.navigationKey)"
                                      [ngClass]="{ 'red': notification.isAlert }">
                                      <span>{{notification.body.action.text}}</span>@if (notification.body.action.navigationKey.length != 0) {
                                      <em
                                        class="material-icons f-13"
                                      >arrow_forward</em>
                                    }
                                  </a>
                                </div>
                              </div>
                            </div>
                          }
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  }
                </div>
                <div class="row  f-13 black col-12 noteHead">
                  <button class="button-primary-medium" style="width: 100%;" (click)="navigateNoteHome($event)">See
                  all</button>
                </div>
              </div>
            </li>
          }
          @if (!isOpsHeader) {
            <li class="nav-item dropdown profile-nav black hide-in-mobile">
              <a class="nav-link" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false"
                [ngClass]="{'selected':currentModule == 'userManagement'}">
                <em class="material-icons">account_circle</em>
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                @if (chkAuth('Profile','Staff Permissions')) {
                  <a class="dropdown-item"
                  [routerLink]="'/user-management/staff-permission/manage-user'">Staff Permissions</a>
                }
                <a class="dropdown-item" [routerLink]="'/user-management/account-settings/profile'">Account
                Settings</a>
                @if (chkAuth('Profile','Unit Settings')) {
                  <a class="dropdown-item" [routerLink]="'/'">Unit
                  Settings</a>
                }
                @if (chkAuth('Profile','Hospital Settings')) {
                  <a class="dropdown-item" [routerLink]="'/'">Hospital
                  Settings</a>
                }
              </div>
            </li>
          }
          @if (isOpsHeader && opsAuthenticated) {
            <li class="nav-item dropdown profile-nav black no-separator">
              <a id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" [ngClass]="{'selected':currentModule == 'userManagement'}">
                <em class="material-icons">account_circle</em>
              </a>
              <div class="dropdown-menu" style="border-top: none !important;" [ngStyle]="{'top': '2px'}"
                aria-labelledby="navbarDropdownMenuLink"> <a class="dropdown-item" (click)="logout()">Logout</a>
              </div>
              <div class=""></div>
            </li>
          }
        </ul>
      }
      @if (authenticated && !isOpsHeader) {
        <button class="navbar-toggler no-border" type="button" data-toggle="collapse"
          data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
          aria-label="Toggle navigation" (click)="toggleHamburgerNav()">
          <em class="navbar-toggler-icon material-icons-round">menu</em>
        </button>
      }
    </div>
    <div class="collapse navbar-collapse unauthenticated" style="justify-content: flex-end;" id="navbarNavDropdown"
      [ngClass]="{'show' : hamburgerOpen,'unauthenticated-nav-bar': !authenticated && !minimalHeader}">
      <div class="mr-auto"></div>
      <div class="clearfix tablet-mode mobile-header-wrapper">
        <em class="material-icons cursor close-icn" (click)="hamburgerOpen = !hamburgerOpen">close</em>
      </div>
      @if (!authenticated && !minimalHeader && !isOpsHeader) {
        <ul class="navbar-nav">
          <li class="nav-item padding-style pt-3">
            <a class="semibold lowercase text-black f-14" (click)="navigateToRequestAccess()">Request access
              <span class="sr-only"></span></a>
            </li>
            <li class="nav-item">
              <input type="submit" class="btn button-primary-large sign-in-btn" (click)="loginClick()"
                value="Sign in">
              </li>
            </ul>
          }
          @if (authenticated || isOpsHeader) {
            <ul class="navbar-nav">
              @if (opsMenu) {
                <li class="nav-item dropdown black">
                  <a class="nav-link" style="padding: 0 40px;" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" [ngClass]="{'selected':currentModule == 'ops'}">
                    ADAM Ops
                    <em class="material-icons down-arrow pull-right">keyboard_arrow_down</em>
                    <em class="material-icons up-arrow pull-right">keyboard_arrow_up</em>
                  </a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    @if (chkAuth('onboard-settings','key')) {
                      <a class="dropdown-item"
                      routerLink="institutions/hospital-onboard">Onboard Hospital(s)</a>
                    }
                    <a class="dropdown-item" routerLink="institutions/preference-upload">Upload Preference
                    Card(s)</a>
                    <a class="dropdown-item" routerLink="institutions/schedule-upload">Upload Schedule(s)</a>
                    <a class="dropdown-item" routerLink="/assessment-tool/accessKey">Assessment Tool</a>
                    <a class="dropdown-item" routerLink="/inventories/device-upload">Upload Device(s)</a>
                    <a class="dropdown-item" routerLink="/pick-request/preference-cards">Preference Cards</a>
                    <a class="dropdown-item" routerLink="/pick-request/list">Pick Requests</a>
                    <a class="dropdown-item" routerLink="/pick-request/schedules">Schedules</a>
                    <a class="dropdown-item" routerLink="/inventories/product-catalog">Product Catalog</a>
                    <a class="dropdown-item" routerLink="/inventories/product-substitute">Product Substitute</a>
                    <a class="dropdown-item" routerLink="/institutions/sku-upload">SKU Mapping</a>
                    <a class="dropdown-item" routerLink="/institutions/hospital-onboard">Hospital Onboard</a>
                  </div>
                </li>
              }
              @if (!isOpsHeader) {
                <li class="nav-item dropdown profile-nav black mobile-list-item">
                  @if (!isOpsHeader) {
                    <a class="nav-link" id="navbarDropdownMenuLink" role="button"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      [ngClass]="{'selected':currentModule == 'userManagement'}">
                      Profile
                      <em class="material-icons down-arrow pull-right">keyboard_arrow_down</em>
                      <em class="material-icons up-arrow pull-right">keyboard_arrow_up</em>
                    </a>
                  }
                  @if (isOpsHeader) {
                    <a id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false"
                      [ngClass]="{'selected':currentModule == 'userManagement'}">
                      Profile
                      <em class="material-icons down-arrow pull-right">keyboard_arrow_down</em>
                      <em class="material-icons up-arrow pull-right">keyboard_arrow_up</em>
                    </a>
                  }
                  <div class="dropdown-menu" style="border-top: none !important;" aria-labelledby="navbarDropdownMenuLink">
                    @if (chkAuth('Profile','Staff Permissions') && !isOpsHeader) {
                      <a class="dropdown-item"
                      (click)="navigateAndClose('/user-management/staff-permission/manage-user',$event)">Staff Permissions</a>
                    }
                    @if (!isOpsHeader) {
                      <a class="dropdown-item" [routerLink]="'/user-management/account-settings/profile'"
                      (click)="navigateAndClose('/user-management/account-settings/profile', $event)">Account Settings</a>
                    }
                    @if (chkAuth('Profile','Unit Settings')) {
                      <a class="dropdown-item"
                      (click)="navigateAndClose('/', $event)">Unit Settings</a>
                    }
                    @if (chkAuth('Profile','Hospital Settings')) {
                      <a class="dropdown-item"
                      (click)="navigateAndClose('/', $event)">Hospital Settings</a>
                    }
                  </div>
                  <div class=""></div>
                </li>
              }
              @if (!isOpsHeader) {
                @for (parentMenu of menuItems; track parentMenu; let i = $index) {
                  <li class="nav-item black">
                    @if (!isMobileFlag || parentMenu.menuName !== 'System Management') {
                      <div>
                        <a class="nav-link custom-a-tag" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false" id={{parentMenu.menuName}} (click)="showMenu(parentMenu,$event)"
                          style="position: relative;"
                          [ngClass]="{'selected':parentMenu.clicked, 'isClicked': parentMenu.clicked === true}">
                          {{parentMenu.menuName}}
                          <em class="material-icons down-arrow pull-right">keyboard_arrow_down</em>
                          <em class="material-icons up-arrow pull-right">keyboard_arrow_up</em>
                        </a>
                        @if (parentMenu.clicked) {
                          <div style="position: relative;">
                            <p-overlayPanel #overlayPanel [showCloseIcon]="true"  class="custom-overlaypanel-pm" (onHide)="showMenu({});">
                              <ng-template pTemplate="content">
                                <div class="content-grid">
                                  @for (subMenuItem of parentMenu.subMenuList; track subMenuItem) {
                                    <div class="dropdown-column">
                                      @for (menuItem of subMenuItem; track menuItem) {
                                        <div>
                                          @if (!menuItem.type || menuItem.type!=='alert') {
                                            <div class="topics">
                                              <div class = "subheading">
                                                @if ((isMobileFlag && menuItem.isAvailableInMobile) || !isMobileFlag) {
                                                  <a class="dropdown-subheading"
                                                  >{{menuItem.category}}</a>
                                                }
                                              </div>
                                              @for (subMenu of menuItem.subMenuNames; track subMenu) {
                                                <div class="dropdown-topics">
                                                  @if ((isMobileFlag && subMenu.isAvailableInMobileScreen) || (!isMobileFlag && subMenu.isAvailableInDesktop)) {
                                                    <a class="custom-item" (click)="hamburgerOpen = false;overlayPanel.hide()"
                                                    [routerLink]="subMenu.link ? subMenu.link : ''">{{subMenu.subMenuName}}</a>
                                                  }
                                                </div>
                                              }
                                            </div>
                                          }
                                          @if (menuItem.type && menuItem.type==='alert') {
                                            <div class="topics">
                                              <div>
                                                <a class="dropdown-subheading"
                                                (click)="hamburgerOpen = false;">{{menuItem.category}}</a>
                                              </div>
                                              <div>
                                                <p-scrollPanel [style]="{width: '100%', 'max-height': '200px'}"
                                                  [styleClass]="'custom-scrollbar'">
                                                  <div class="notificationElement">
                                                    @if (systemAlerts) {
                                                      <div class="alert-container">
                                                        @for (subMenu of menuItem.subMenuNames; track subMenu) {
                                                          <div class="alert-container"
                                                            >
                                                            <a class="alerts"
                                                              (click)="navigateToNotificationFlow($event,subMenu.notificationId, subMenu.category, subMenu.body.action.params,subMenu.body.action.navigationKey);overlayPanel.hide()">
                                                              <span>{{subMenu.body.content.msg}}</span>
                                                            </a>
                                                          </div>
                                                        }
                                                      </div>
                                                    }
                                                  </div>
                                                </p-scrollPanel>
                                              </div>
                                            </div>
                                          }
                                        </div>
                                      }
                                    </div>
                                  }
                                </div>
                              </ng-template>
                            </p-overlayPanel>
                          </div>
                        }
                      </div>
                    }
                  </li>
                }
              }
              @if (!isOpsHeader) {
                <li class="nav-item notification-nav dropdown pr-3 desktop-mode">
                  <a class="nav-link" (click)="toggleNotificationNav($event)"
                    [ngClass]="{'selected':currentModule == 'notifications'}">
                    <em class="material-icons notification"
                    [ngClass]="{'no-hover':notificationCount == 0}">notifications</em>
                    <span class="badge medium f-13">{{notificationCount}}</span>
                  </a>
                  <div class="dropdown-menu notListDropdown"
                    [ngClass]="{ 'show': navbarOpen,'height-auto':notifications.length < 4}"
                    aria-labelledby="navbarDropdownMenuLink">
                    <div class="row">
                      <div class="row f-14 black col-12 noteHead">
                        Notifications
                      </div>
                    </div>
                    <div class="row itemContainer notificationTrayBlock" #notificationTray>
                      @for (item of mapOfAlerts; track item) {
                        <div class="notificationElement">
                          <mat-accordion [togglePosition]="'before'">
                            <mat-expansion-panel (opened)="panelOpenState = true"
                              (closed)="panelOpenState = false">
                              <mat-expansion-panel-header class="matPanelHeader">
                                <mat-panel-title>
                                  <div class="row" style="width: 100% !important;">
                                    <div class="f-14 black col-7 noteHead" style="padding: 14px 0px !important;">
                                      <div class="bold f-12 text-left contentMsg" style="margin-top: 3px;">
                                        <span>{{loadNotification(notificationCategories,
                                        returnGroupDetails(item.key,","))}}</span>
                                        <span
                                          class="badge small f-11 groupBadge">{{returnGroupCountDetails(item.key,",")}}
                                        </span>
                                      </div>
                                    </div>
                                    <div class="f-11 black col-5 noteHead dismissBlock">
                                      <div class="dismissButton">
                                        <span style="font-size: 10px !important;"
                                          (click)="dismissTrayGroupNotification(returnGroupDetails(item.key,','), returnGroupCountDetails(item.key,',') )">
                                          Dismiss All
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </mat-panel-title>
                              </mat-expansion-panel-header>
                              @for (notification of item.value; track notification) {
                                <div class="row noteItem done">
                                  <div class="noteImg text-center">
                                    @if (notification.isAlert) {
                                      <em
                                      class="material-icons-round warning">warning</em>
                                    }
                                  </div>
                                  <div class="noteContent">
                                    <div class="bold f-14 text-left contentMsg">
                                      {{notification.body.content.msg}}
                                    </div>
                                    @if (!(notification.notificationDate | customDate : 'isToday')) {
                                      <div class="regular f-12 text-grey"
                                        >
                                        {{notification.notificationDate | customDate : 'date'}}
                                      </div>
                                    }
                                    @if ((notification.notificationDate | customDate : 'isToday')) {
                                      <div class="regular f-12 text-grey"
                                        >
                                      {{notification.timeStamp}} ago</div>
                                    }
                                    <div class="noteAction text-left semibold f-14">
                                      <a (click)="navigateToNotificationFlow($event,notification.notificationId, notification.category, notification.body.action.params,notification.body.action.navigationKey)"
                                        [ngClass]="{ 'red': notification.isAlert }">
                                        <span>{{notification.body.action.text}}</span>@if (notification.body.action.navigationKey.length != 0) {
                                        <em
                                          class="material-icons f-13"
                                        >arrow_forward</em>
                                      }
                                    </a>
                                  </div>
                                </div>
                              </div>
                            }
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    }
                  </div>
                  <div class="row  f-13 black col-12 noteHead">
                    <button class="button-primary-medium" style="width: 100%;" (click)="navigateNoteHome($event)">See
                    all</button>
                  </div>
                </div>
              </li>
            }
            @if (!isOpsHeader) {
              <li class="nav-item dropdown profile-nav black desktop-mode hide-in-mobile">
                @if (!isOpsHeader) {
                  <a class="nav-link" id="navbarDropdownMenuLink" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    [ngClass]="{'selected':currentModule == 'userManagement'}">
                    <em class="material-icons">account_circle</em>
                  </a>
                }
                @if (isOpsHeader) {
                  <a id="navbarDropdownMenuLink" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false"
                    [ngClass]="{'selected':currentModule == 'userManagement'}">
                    <em class="material-icons">account_circle</em>
                  </a>
                }
                <div class="dropdown-menu" style="border-top: none !important;" aria-labelledby="navbarDropdownMenuLink">
                  @if (chkAuth('Profile','Staff Permissions') && !isOpsHeader) {
                    <a class="dropdown-item"
                    [routerLink]="'/user-management/staff-permission/manage-user'">Staff Permissions</a>
                  }
                  @if (!isOpsHeader) {
                    <a class="dropdown-item" [routerLink]="'/user-management/account-settings/profile'"
                      >Account
                    Settings</a>
                  }
                  @if (chkAuth('Profile','Unit Settings')) {
                    <a class="dropdown-item" [routerLink]="'/'">Unit
                    Settings</a>
                  }
                  @if (chkAuth('Profile','Hospital Settings')) {
                    <a class="dropdown-item" [routerLink]="'/'">Hospital
                    Settings</a>
                  }
                  <a class="dropdown-item" (click)="logout()">Logout</a>
                </div>
                <div class=""></div>
              </li>
            }
            @if (isOpsHeader && opsAuthenticated) {
              <li class="nav-item dropdown profile-nav black desktop-mode hide-in-mobile"
                >
                <a id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false" [ngClass]="{'selected':currentModule == 'userManagement'}">
                  <em class="material-icons">account_circle</em>
                </a>
                <div class="dropdown-menu" style="border-top: none !important;" [ngStyle]="{'top': '2px'}"
                  aria-labelledby="navbarDropdownMenuLink"> <a class="dropdown-item" (click)="logout()">Logout</a>
                </div>
                <div class=""></div>
              </li>
            }
            <div class="logout-dropdown-item tablet-mode">
              <a class="" (click)="logout()">Logout</a>
            </div>
          </ul>
        }
      </div>
    </nav>
    <span class="tablet-mode hide-in-desktop">
      @if (showLogo && !minimalHeader && !isOpsHeader) {
        <app-search-bar [toggleSearchBar]="toggleSearchBar">
        </app-search-bar>
      }
    </span>
  </div>
}

<p-dialog [(visible)]="showMultitenanceError" styleClass="customDialog common-dialog confirmation-dialog"
  [closable]="false" [modal]="true" [responsive]="true" [minY]="70" [baseZIndex]="10000">
  <p-header>
    <div style="text-align: right;"><em class="material-icons close-icon"
    (click)="showMultitenanceError=false">close</em></div>
  </p-header>
  <div class="dialog-body-content">
    <p class="alert-text">{{ adam_labels.error_messages.invalidTenantError }}</p>
  </div>
  <p-footer>
    <div style="padding-bottom: 34px;">
      <button type="button" class="btn button-primary-small semibold" (click)="showMultitenanceError=false;"
      style="width:134px;height:48px;">{{adam_labels.common.okayButton}}</button>
    </div>
  </p-footer>
</p-dialog>