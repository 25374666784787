<div class="clearfix mt-2">
    <span class="card-item-title float-start">{{tileTitle}}</span>
    <span class="card-item-value float-end">{{pickingValue}}</span>
</div>
<div class="tile-sub-heading">
    Total Cases: {{pickingSummaryTotalCaseCount}} ({{pickingSummaryCompletedCaseCount}} completed)
</div>
<div class="text-end div-height">
    <a [ngClass]="isPrintEnabled ? 'action-icons' : 'inactive-icons'" (click)="isPrintEnabled ? printOrder() : ''" (keypress)="isPrintEnabled ? printOrder() : ''">
        <span class="semibold f-12 print-clr print-case">{{adamLabels.materialManagement_module.printList}}</span>
    </a>
</div>
<div class="picking-summary-cases mb-3">
    <p-tabView>
        <p-tabPanel header="{{adamLabels.materialManagement_module.pickingSummaryTab1}}">
            <p-table [columns]="colsWIP" [value]="WIPData" [scrollable]="true" scrollHeight="150px" sortField="origin" [sortOrder]="1" [style.width]="'100%'">
                <ng-template pTemplate="header" let-columns>
                    <tr class="table-header-row">
                        <th *ngFor="let col of columns" id="titleWIP" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                            <div>
                                <span>{{col.header}}</span>
                                <p-sortIcon class="picking-summary-sort" field="{{col.field}}" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-WIPData let-columns="columns">
                    <tr>
                        <td class="picking-summary-td-origin">
                            <span pTooltip="{{WIPData.origin | pickRequest}}" tooltipPosition="top">{{WIPData.origin | pickRequest}}</span>
                        </td>
                        <td class="picking-summary-td-case">
                            <a class="d-block" (click)="goToScheduleDetails(WIPData.pickRequestId)" (keypress)="goToScheduleDetails(WIPData.pickRequestId)">{{WIPData.scheduleCode}}</a>
                        </td>
                        <td class="picking-summary-td-status">
                            <span pTooltip="{{WIPData.status | pickRequest}}" tooltipPosition="top">{{WIPData.status | pickRequest}}</span>
                        </td>
                        <td class="picking-summary-td-quantity">
                            {{WIPData.quantity}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr *ngIf="WIPData.length === 0" class="table-footer-row">
                        <td colspan="4" class="text-center">{{adamLabels.materialManagement_module.noCaseFound}}</td>
                    </tr> 
                </ng-template>
            </p-table>
        </p-tabPanel>
        <p-tabPanel header="{{adamLabels.materialManagement_module.pickingSummaryTab2}}">
            <p-table [columns]="colsWIP" [value]="completedData" [scrollable]="true" scrollHeight="150px" sortField="origin" [sortOrder]="1">
                <ng-template pTemplate="header" let-columns>
                    <tr class="table-header-row">
                        <th *ngFor="let col of columns" id="titleCompleted" [pSortableColumn]="col.field" [ngStyle]="{'width': col.width}">
                            <div>
                                <span>{{col.header}}</span>
                                <p-sortIcon class="picking-summary-sort" field="{{col.field}}" ariaLabel="Activate to sort" ariaLabelDesc="Activate to sort in descending order" ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-completedData let-columns="columns">
                    <tr>
                        <td class="picking-summary-td-origin">
                            {{completedData.origin | pickRequest}}
                        </td>
                        <td class="picking-summary-td-case">
                            <a class="d-block" (click)="goToScheduleDetails(completedData.pickRequestId)" (keypress)="goToScheduleDetails(completedData.pickRequestId)">{{completedData.scheduleCode}}</a>
                        </td>
                        <td class="picking-summary-td-status">
                            <span pTooltip="{{completedData.status | pickRequest}}" tooltipPosition="top">{{completedData.status | pickRequest}}</span>
                        </td>
                        <td class="picking-summary-td-quantity">
                            {{completedData.quantity}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr *ngIf="completedData.length === 0" class="table-footer-row">
                        <td colspan="4" class="text-center">{{adamLabels.materialManagement_module.noCaseFound}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
    </p-tabView>
</div>
<div class="row coverage mt-2">
    <div class="col-xl-12 col-lg-12 col-md-12 mb-1 column no-margin">
        <span class="card-item-title float-start coverage-font">{{adamLabels.materialManagement_module.coverage}}</span>
    </div>
    <div [ngClass]="getClass()" class="remove-right-padding">
        <div class="coverage-title">
            <span class="d-inline-block">{{kioskTitle}}:</span>
            <div class="coverage-value">{{kioskValue}} ({{coverageKioskCount}})</div>
        </div>
    </div>
    <div [ngClass]="getClass()" class="remove-padding">
        <div class="coverage-title">
            <span class="d-inline-block">{{smartShelfTitle}}:</span>
            <div class="coverage-value">{{smartShelfValue}} ({{coverageShelfCount}})</div>
        </div>
    </div>
    <div [ngClass]="getClass()" *ngIf="partialTitle" class="remove-padding">
        <div class="coverage-title" *ngIf="partialTitle">
            <span class="d-inline-block">{{partialTitle}}:</span>
            <div class="coverage-value">{{partialValue}} ({{coveragePartialCount}})</div>
        </div>
    </div>
    <div [ngClass]="getClass()" class="remove-padding">
        <div class="coverage-title" *ngIf="smartRoomTitle">
            <span class="d-inline-block">{{smartRoomTitle}}:</span>
            <div class="coverage-value">({{coverageSmartRoomCount}})</div>
        </div>
    </div>
</div>
