import { Component, Input, Output, EventEmitter, OnInit,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AdamConf } from '@app/app.config';
import { DialogModule } from 'primeng/dialog';

import {PreferenceCardAccuracyTileComponent} from 'src/app/core/dashboard/clinical/preference-card-accuracy-tile/preference-card-accuracy-tile.component'
import { OrScanningTileComponent } from '../or-scanning-tile/or-scanning-tile.component';
import { FinancialOverviewTileComponent } from '../financial-overview-tile/financial-overview-tile.component';
import { ProductsUsageReturnsTileComponent } from '../products-usage-returns-tile/products-usage-returns-tile.component';
import { MagnifiedViewModel } from '../../models/materials-management-dashboard.model';

@Component({
  selector: 'app-tile-magnifier',
  templateUrl: './tile-magnifier.component.html',
  styleUrls: ['./tile-magnifier.component.scss'],
  standalone: true,
  imports:[PreferenceCardAccuracyTileComponent,ProductsUsageReturnsTileComponent,OrScanningTileComponent,FinancialOverviewTileComponent,DialogModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TileMagnifierComponent implements OnInit {
  @Input() showMagnifiedViewPopupData: any;
  @Output() showMagnifiedTilePopup = new EventEmitter<boolean>();
  public adamLabels: any;

  constructor() {
    this.adamLabels = AdamConf;
  }

  public ngOnInit(): void {
    // OnInit 
  }

  public showMagnifiedPopup(event: any): void {
    this.showMagnifiedViewPopupData = event;
    this.showMagnifiedTilePopup.emit(this.showMagnifiedViewPopupData);
  }

}
