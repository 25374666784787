import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { ChartDataItem, ChartConfig, BarConfig, MousePosition } from '@app/shared/models/grouped-bar-chart.model';

@Component({
  selector: 'app-shared-grouped-bar-chart',
  templateUrl: './grouped-bar-chart.component.html',
  styleUrls: ['./grouped-bar-chart.component.scss'],
  standalone: true
})
export class GroupedBarChartComponent implements OnChanges {

  @Input() boxesOrderedReplenishedBarData!: ChartDataItem[];
  @Input() barConfig!: BarConfig;
  @Input() chartMetaData!: ChartConfig;
  @ViewChild('chartContainer', { static: false })
  private chartContainer!: ElementRef;

  constructor() { }

  public ngOnChanges(): void {
    if (this.boxesOrderedReplenishedBarData && this.boxesOrderedReplenishedBarData.length > 0) {
      setTimeout(() => {
        this.initChart(this.boxesOrderedReplenishedBarData, this.barConfig, this.chartMetaData);
      }, 100);
    }
  }

  private initChart(data: ChartDataItem[], colorConfig: BarConfig, chartMetaData: ChartConfig): void {
    const width = this.chartContainer.nativeElement.offsetWidth - chartMetaData.margin.left - chartMetaData.margin.right;
    const height = this.chartContainer.nativeElement.offsetHeight - chartMetaData.margin.bottom - chartMetaData.margin.top;
    const x0 = d3.scaleBand().rangeRound([0, width]).padding(0.1);
    const x1 = d3.scaleBand();
    const y = d3.scaleLinear().range([height, 0]);
    const xAxis = d3.axisBottom(x0).tickSize(0);
    const yAxis = d3.axisLeft(y).ticks(4);
    const color = (key: string) => colorConfig[key];

    let svg = d3.select(this.chartContainer.nativeElement)
      .html('')
      .append('svg')
      .attr('width', width + chartMetaData.margin.left + chartMetaData.margin.right)
      .attr('height', height + chartMetaData.margin.bottom + chartMetaData.margin.top)
      .append('g')
      .attr('transform', `translate(${chartMetaData.margin.left},${chartMetaData.margin.top})`);

    svg.append('g')
      .attr('class', 'y yLabel')
      .append('text')
      .attr('width', height)
      .attr('y', 6)
      .attr('dy', '.71em')
      .style('font-weight', 'bold')
      .style('font-size', '16px')
      .style('fill', '#63666A')
      .attr('transform', `translate(0, ${(height / 2 + chartMetaData.margin.top)}) rotate(-90)`);

    const categoryNames = data.map(d => d.category);
    const barNames = data[0].barChartData.map(d => d.label);
    x0.domain(categoryNames);
    x1.domain(barNames).rangeRound([0, x0.bandwidth()]);
    y.domain([0, (d3.max(data, category => d3.max(category.barChartData, d => +d.value || 0)) ?? 0) + 1]);

    svg.append('g')
      .attr('class', 'x axis xAxis')
      .attr('transform', `translate(0,${height})`)
      .style('font-weight', 'bold')
      .style('fill', '#63666A')
      .call(xAxis);

    svg.append('g')
      .attr('class', 'y axis yAxis')
      .style('opacity', '0')
      .style('font-weight', 'bold')
      .style('fill', '#63666A')
      .call(yAxis);

    svg.selectAll('g.yAxis g.tick')
      .append('line')
      .attr('class', 'gridline y')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', width)
      .attr('stroke-width', 2)
      .attr('stroke-dasharray', '5, 5')
      .style('opacity', '1')
      .style('stroke', '#d0cfcd')
      .attr('y2', 0);

    // Add styles to X axis ticks
    svg.selectAll('g.xAxis g.tick text')
      .style('text-anchor', 'end')
      .style('font-size', '10px')
      .style('font-family', 'Noto Sans Regular')
      .attr('transform', `translate(${(width / data.length) / 6}, 0),rotate(-45)`);

    svg.selectAll('.domain').style('fill', '#fff');

    let slice = svg.selectAll<SVGGElement, ChartDataItem>('.slice')
      .data(data)
      .enter().append('g')
      .attr('class', 'g')
      .attr('transform', d => `translate(${x0(d.category)},0)`);

      slice = slice.selectAll<SVGGElement, ChartDataItem>('g.barItem')
      .data(d => d.barChartData as unknown as ChartDataItem[])
      .enter()
      .append('g')
      .attr('class', 'barItem');
      slice.append('rect')
      .attr('width', x1.bandwidth())
      .attr('x', d => x1(String(d.label))!) // Ensure d.label is a string
      .style('fill', d => color(String(d.label))) // Ensure d.label is a string
      .attr('y', y(0))
      .attr('height', height - y(0))
      .on('mouseover', (event, d) => {
        const div = d3.select('body').append('div')
          .attr('class', 'tooltip-chart-line')
          .style('opacity', 0);
        div.transition()
          .duration(200)
          .style('opacity', .9);
        div.html(this.getTooltipContent(d))
          .style('left', () => {
            const pos = this.positionTooltip({
              x: event.pageX, y: event.pageY,
              top: 0,
              left: 0
            });
            return `${pos.left + 10}px`;
          })
          .style('top', `${event.pageY - 28}px`)
          .style('z-index', '10001');
      })
      .on('mouseout', () => {
        d3.selectAll('.tooltip-chart-line').remove();
      });
    
    

      slice.selectAll<SVGRectElement, ChartDataItem>('rect')
  .transition()
  .delay(() => {
    let array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return Number(array[0].toString()[0]) * 100;
  })
  .duration(1000)
  .attr('y', (d: ChartDataItem) => y(Number(d.value)) === height ? y(Number(d.value)) - 1 : y(Number(d.value)))
  .attr('height', (d: ChartDataItem) => height - y(Number(d.value)));

svg.select('.y').transition().duration(500).delay(1300).style('opacity', '1');

    
  }

  private positionTooltip(mouse: MousePosition): MousePosition {
    const box = document.querySelector('.graph-table');
    if (box) {
      const style = getComputedStyle(box);
      const toolwidth = parseInt(style.width);
      if (window.innerWidth - (mouse.x + toolwidth) < 100) {
        mouse.x = mouse.x - toolwidth - 30;
      }
      return { top: mouse.y, left: mouse.x, x: mouse.x, y: mouse.y };
    }
    return mouse;
  }
  
  private getTooltipContent(d: any): string {
    let tooltipContent = '';
    if (d.toolTipInfo) {
      tooltipContent = '<table class="graph-table">';
      d.toolTipInfo.forEach((element: any) => {
        tooltipContent += `<tr><td class="title">${element.label}:</td><td class="data-value"> ${element.value} </td></tr>`;
      });
      tooltipContent += '</table>';
    }
    return tooltipContent;
  }
}
