<div class="summary-tile">
  <div class="tile-body">
    <div class="title-wrapper clearfix">
      <span class="item-title float-start">{{label}}</span>
    </div>
    <div class="summary-container">
      <div class="summary-body">
        <div class="summary-image" (click)="navigateToOtherPage(label)" (keydown)="navigateToOtherPage(label)" tabindex="0">
          <span class="hyperlinked-icon">
            @if (label === 'Ordering Summary') {
              <span class="material-icons-outlined summary-image">
                shopping_cart
              </span>
            }
            @if (label === 'Restocking Summary') {
              <span class="material-icons-outlined summary-image">
                inventory_2
              </span>
            }
            @if (label === 'Replenishment Summary') {
              <span class="material-icons-outlined summary-image">
                inbox
              </span>
            }
            @if (label === 'Transfers Summary') {
              <span class="material-icons-outlined summary-image image-cursor">
                move_down
              </span>
            }
          </span>
        </div>
        <span class="summary-info">
          <p class="first-summary-label">
            <span class="tile-label">{{firstLabel}}</span>
            <span class="tile-value">{{firstValue}}</span>
          </p>
          <p class="second-summary-label">
            <span class="tile-label">{{secondLabel}}</span>
            <span class="tile-value">{{secondValue}}</span>
          </p>
        </span>
      </div>
    </div>
  </div>
</div>
