<div class="outer">
  <div class="tile-heading display"> OR Scanning
    <button class="btn btn-close-white button-info" (click)="navigateToORScanningOverview()">
      <em class="material-icons">info_outline</em>
    </button>
    <div class="filter">
      <div class="filter-container" (click)="displayFilter()">
        <p class="filter-text">Filters <em class="material-icons align-middle filter-icon">filter_list</em></p>
      </div>
      @if (showMagnifiedViewPopupData.isMagnifiedView===false) {
        <div class="display  click" (click)="showMagnifiedView()">
          <img class="expand-img" src="./assets/img/maximize_icon.png" alt="Expand image" />
        </div>
      }
      @if (showMagnifiedViewPopupData.isMagnifiedView===true) {
        <div class="display mr-2 click" (click)="showMagnifiedView()">
          <img class="expand-img" src="./assets/img/minimize_icon.png" alt="Expand image" />
        </div>
      }
    </div>
  </div>
  <div class="row mr-2 tile-body">
    <div class="col-xl-2 col-lg-2 col-md-3 mb-4 column">
      <div class="mtd-ytd-container mt-3 mtd-tile">
        <p class="mtd-ytd-text mt-2">MTD:</p>
        <p class="mtd-ytd-number">{{mtd}}%</p>
      </div>
      <div class="mtd-ytd-container tile">
        <p class="mtd-ytd-text mt-2">YTD:</p>
        <p class="mtd-ytd-number">{{ytd}}%</p>
      </div>
    </div>
    <div class="col-xl-10 col-lg-8 col-md-6 column graph-data">
      <div class="pl-3 text-center">
        <div class="legend display">
          <span class="nurse-scanned display"></span>
          <p class="legend-text display ml-1">{{adamLabels.clinical_module.orScanningBar1LegendLabel}}</p>
          <span class="auto-closed display ml-2"></span>
          <p class="legend-text display ml-1">{{adamLabels.clinical_module.orScanningBar2LegendLabel}}</p>
          <span class="scanned-code display ml-2"></span>
          <p class="legend-text display ml-1">{{adamLabels.clinical_module.orScanningBar3LegendLabel}}</p>
          <p class="legend-text display ml-2">
            <em class="material-icons align-middle">commit</em> {{adamLabels.clinical_module.orScanningLineLegendLabel}}</p>
          </div>
        </div>
        <app-stacked-bar-chart [chartData]="chartData" [chartMetaData]="chartMetaData"></app-stacked-bar-chart>
      </div>
    </div>

  </div>