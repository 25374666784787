<div class="pricing-disclosure">
    <div class="row">
        <div class="offset-xl-2 offset-lg-1 offset-md-0 offset-sm-0 col-lg-8 col-xl-6 col-md-9 col-sm-8 col-xs-12">
            <header class="header1 black">Pricing disclosure</header>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>
                AITA is intended to assist hospital stakeholders, who may be involved in controlling costs and informing
                purchasing choices, in technology appraisals related to the use of J&J MedTech (JJMT) products. AITA is
                not intended to promote or recommend use of any JJMT product beyond its labeled indication. As always,
                please refer to the Instructions for Use (IFU) included in package inserts and other labeling for a
                complete description of specific information, including indications, contraindications, warnings, and
                precautions, related to any device prior to use. IFUs for JJMT products can be found at the <a rel="JJMT e-IFU
                website"
                href="https://www.e-ifu.com/welcome" target="_blank"
                data-mce-href="https://www.e-ifu.com/welcome">JJMT e-IFU
                website</a>.
            </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>
                Health care providers contemplating using a particular manufacturer’s product must ensure that it’s
                contemplated use of the product, individually and in procedures, is in accordance with the product’s IFU
                and approvals or clearances of regulatory authorities. The information being provided is not a
                substitute for clinical judgement. Information should be carefully considered along with clinical
                experience, treatment patterns and other factors pertaining to the specific institution. We do not
                represent or guarantee that any products suggested by AITA for individual non-JJMT products, or for
                procedures of non-JJMT products, are safe, effective, in accordance with instructions for use, or
                approved or cleared by regulatory authorities.
            </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>
                <span class="black f-14">Within the AITA Engine, product cost information is utilized to show estimated value of inventory on
                hand, procedure and case costs and value of product to order and already ordered.</span> Additionally, AITA
                provides comparisons of various manufacturers’ pricing on an item-by-item basis and on a procedure
                basis. AITA’s comparison of products and procedures between manufacturers is based on our knowledge of
                JJMT’s products and our good-faith assessment of available information concerning other manufacturer’s
                products. AITA’s comparison of products and procedures between manufacturers is solely intended to
                assist health care providers in assessing the approximate price difference between similar products made
                by different manufacturers and should not be relied upon for any other purpose.
            </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>
                By using this resource, you agree AITA is being provided ‘as is’ and is intended for use by only
                qualified hospital stakeholders. JJMT makes no claims concerning the accuracy of the information
                contained herein. AITA is intended solely to project hypothetical savings regarding the selected product
                mix and may not represent any actual savings you will realize upon entering into a subscription
                agreement. The information provided by AITA represents no statement, promise or guarantee by JJMT that a
                user will realize the hypothetical savings herein nor should any information herein be used for price
                loading, predicting or assisting with reimbursement or billing, reference, or other purposes.
            </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p>
                Neither JJMT nor any other party involved in the preparation of AITA shall be liable to you or others
                for any decisions made or actions taken by you or others in reliance on information provided in AITA.
            </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="h"> </p>
            <p class="disclaimer">
                The third-party trademarks used herein are the trademarks of their respective owners.
            </p>
        </div>
    </div>
</div>
