import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

import { ClinicalService } from '../clinical.service';
import { Subscription, timer } from 'rxjs';
import { TileDataApiResponse, TileFilterInfo } from 'src/app/core/dashboard/models/clinical-dashboard-model.model';
import { AdamConf } from '@app/app.config';
import { LoggerService } from '@app/core/logger.service';
import { AuthService } from '@app/auth/auth.service';
import {LineChartClinicalComponent} from '../tile-graphs/line-chart-clinical/line-chart-clinical.component';
import { TileMagnifierComponent } from '../tile-magnifier/tile-magnifier.component';
@Component({
  selector: 'app-financial-overview-tile',
  standalone: true,
  imports: [LineChartClinicalComponent, TileMagnifierComponent],
  templateUrl: './financial-overview-tile.component.html',
  styleUrls: ['./financial-overview-tile.component.scss']
})
export class FinancialOverviewTileComponent implements OnInit, OnDestroy {

  public isDisplayFilter = false;
  public filterConfig: any;
  public adamLabels = AdamConf;
  public cumulativeYTDBudget: string;
  public cumulativeYTDActual: string;
  public deltaYTDDollars: string;
  public deltaYTDPercent: string;
  public deltaYTDIndicator: string;
  public yearEndForecastBudget: string;
  public yearEndForecastActual: string;
  public forecastedYearEndDeltaDollars: string;
  public forecastedYearEndDeltaPercent: string;
  public forecastedYearEndDeltaIndicator: string;
  private filterSubscription$: Subscription;
  private dataSubscription$: Subscription;
  private ticker$: Subscription;
  @Input() showMagnifiedViewPopupData: any;
  @Output() showMagnifiedPopup = new EventEmitter<string[]>();
  public lineChartData: any;
  public chartMetaData: any;
  public financialOverviewParams: TileFilterInfo;
  public deltaYTDDirection: string;
  public forecastedYearEndDeltaDirection: string;

  constructor(
    private readonly clinicalService: ClinicalService,
    private readonly logger: LoggerService,
    private readonly authService: AuthService
  ) {
    this.chartMetaData = {
      type: 'financial-overview-tile',
      xAxis: 'date',
      yAxis: 'currency',
      margin: {
        top: 20,
        right: 20,
        bottom: 45,
        left: 50
      }
    };
  }

  public ngOnInit(): void {
    this.loadFinancialOverviewTileData();
    this.setAutoRefresh();
  }

  public ngOnDestroy(): void {
    this.resetFinancialOverviewSubscriptions();
    if (this.ticker$) {
      this.ticker$.unsubscribe();
    }
  }

  private resetFinancialOverviewSubscriptions(): void {
    if (this.dataSubscription$) {
      this.dataSubscription$.unsubscribe();
    }
    if (this.filterSubscription$) {
      this.filterSubscription$.unsubscribe();
    }
  }

  private setAutoRefresh(): void {
    const currentUser = this.authService.getCurrentUser();
    if (currentUser?.isAutoRefreshEnabled && !this.showMagnifiedViewPopupData.isMagnifiedView) {
      const delayToStartTimer = currentUser.autoRefreshTimeInterval ?? 60000; // Default to 60 seconds if not defined
      const timerInterval = delayToStartTimer;
      const source = timer(delayToStartTimer, timerInterval);
      this.ticker$ = source.subscribe(() => {
        this.logger.log('Refreshing Financial Overview tile data');
        this.loadFinancialOverviewTileData();
      });
    }
  }

  private loadFinancialOverviewTileData(): void {
    this.resetFinancialOverviewSubscriptions();
    this.filterSubscription$ = this.clinicalService.financialOverviewFilter$.subscribe((data) => {
      if (data) {
        this.financialOverviewParams = {
          tileName: 'Financial Overview',
          tileFilters: data
        };
        this.getDataByFilters();
      }
    });
  }

  private getDataByFilters(): void {
    if (this.dataSubscription$) {
      this.dataSubscription$.unsubscribe();
    }
    this.dataSubscription$ = this.clinicalService.getClinicalTileData(this.financialOverviewParams)
      .subscribe((dataItems) => {
        if (dataItems.responseCode === '2001') {
          this.lineChartData = dataItems.responseData;
          if (dataItems.responseData.calloutData && dataItems.responseData.calloutData.length > 0) {
            this.setFinancialOverviewData(dataItems);
          }
        }
      });
  }

  private setFinancialOverviewData(dataItems: TileDataApiResponse): void {
    dataItems.responseData.calloutData.forEach((tileData) => {
      switch (tileData.label) {
        case 'Cumulative YTD':
          this.cumulativeYTDBudget = tileData.firstDetailValue;
          this.cumulativeYTDActual = tileData.secondDetailValue;
          break;
        case 'Delta YTD':
          this.deltaYTDDollars = tileData.firstDetailValue;
          this.deltaYTDPercent = tileData.secondDetailValue;
          this.deltaYTDIndicator = tileData.trendColor ? tileData.trendColor.toUpperCase() : '';
          this.deltaYTDDirection = tileData.trendDirection ? tileData.trendDirection.toUpperCase() : '';
          break;
        case 'Year-End Forecast':
          this.yearEndForecastBudget = tileData.firstDetailValue;
          this.yearEndForecastActual = tileData.secondDetailValue;
          break;
        case 'Forecasted Year-End Delta':
          this.forecastedYearEndDeltaDollars = tileData.firstDetailValue;
          this.forecastedYearEndDeltaPercent = tileData.secondDetailValue;
          this.forecastedYearEndDeltaIndicator = tileData.trendColor ? tileData.trendColor.toUpperCase() : '';
          this.forecastedYearEndDeltaDirection = tileData.trendDirection ? tileData.trendDirection.toUpperCase() : '';
          break;
      }
    });
  }
  

  public displayFilter(): void {
    this.isDisplayFilter = true;
    this.filterConfig = {
      isDisplayFilter: this.isDisplayFilter,
      tile: 'Financial Overview'
    };
    this.clinicalService.setFilterPopupState(this.filterConfig);
    this.showMagnifiedViewPopupData.isMagnifiedView = false;
  }

  public showMagnifiedView(): void {
    this.showMagnifiedViewPopupData.isMagnifiedView = !this.showMagnifiedViewPopupData.isMagnifiedView;
    this.showMagnifiedViewPopupData.tile = this.showMagnifiedViewPopupData.isMagnifiedView ? this.adamLabels.clinical_module.financialOverviewLabel : '';
    this.showMagnifiedPopup.emit(this.showMagnifiedViewPopupData);
  }

}
