<p-dialog [(visible)]="showMagnifiedViewPopupData['isMagnifiedView']" [style]="{width: '55vw'}" [baseZIndex]="10000" [showHeader]="false" [modal]="true" [responsive]="true" [closeOnEscape]="false" [closable]="false" styleClass="customDialog">
    <div class="popup-container">
        <app-core-dashboard-materials-management-dispensed-restocked-tile 
            [showMagnifiedViewPopupData]="showMagnifiedViewPopupData" 
            (showMagnifiedPopup)="showMagnifiedPopup($event)" 
            [hidden]="showMagnifiedViewPopupData['tile'] !== adamLabels.materialManagement_module.dispensedRestockedLabel">
        </app-core-dashboard-materials-management-dispensed-restocked-tile>
        
        <app-core-dashboard-materials-management-inventory-tile 
            (showMagnifiedPopup)="showMagnifiedPopup($event)" 
            [showMagnifiedViewPopupData]="showMagnifiedViewPopupData" 
            [hidden]="showMagnifiedViewPopupData['tile'] !== adamLabels.materialManagement_module.inventoryTileName">
        </app-core-dashboard-materials-management-inventory-tile>
        
        <app-core-dashboard-materials-management-today-progress-tracker-tile 
            (showMagnifiedPopup)="showMagnifiedPopup($event)" 
            [showMagnifiedViewPopupData]="showMagnifiedViewPopupData" 
            [hidden]="showMagnifiedViewPopupData['tile'] !== adamLabels.materialManagement_module.todayProgressTileName">
        </app-core-dashboard-materials-management-today-progress-tracker-tile>
        
        <app-core-dashboard-materials-management-boxes-ordered-replenished-tile 
            (showMagnifiedPopup)="showMagnifiedPopup($event)" 
            [showMagnifiedViewPopupData]="showMagnifiedViewPopupData" 
            [hidden]="showMagnifiedViewPopupData['tile'] !== adamLabels.materialManagement_module.boxesOrderedReplenishedTileName">
        </app-core-dashboard-materials-management-boxes-ordered-replenished-tile>
    </div>
</p-dialog>
