import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-privacy-policy',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {
  email1: SafeHtml;
  email2: SafeHtml;
  email3: SafeHtml;

  constructor(private sanitizer: DomSanitizer,
    private router: Router
  ) {
    this.email1 = this.sanitizer.bypassSecurityTrustHtml('<a rel="noopener noreferrer" href="mailto:jnjmedicaldevices@its.jnj.com">jnjmedicaldevices@its.jnj.com</a>');
    this.email2 = this.sanitizer.bypassSecurityTrustHtml('<a rel="noopener noreferrer" href="mailto:jnjmedicaldevices@its.jnj.com">jnjmedicaldevices@its.jnj.com</a>');
    this.email3 = this.sanitizer.bypassSecurityTrustHtml('<a rel="noopener noreferrer" href="mailto:jnjmedicaldevices@its.jnj.com">jnjmedicaldevices@its.jnj.com</a>');
  }

}
