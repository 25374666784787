import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FilterApiResponse } from 'src/app/core/dashboard/models/clinical-dashboard-model.model';
import { ClinicalService } from '../clinical.service';
import { PreferenceCardAccuracyTileComponent } from '../preference-card-accuracy-tile/preference-card-accuracy-tile.component';
import { FinancialOverviewTileComponent } from '../financial-overview-tile/financial-overview-tile.component';
import { OrScanningTileComponent } from '../or-scanning-tile/or-scanning-tile.component';
import { ProductsUsageReturnsTileComponent } from '../products-usage-returns-tile/products-usage-returns-tile.component'
import { PreferenceCardOverviewTileComponent} from '../preference-card-overview-tile/preference-card-overview-tile.component'
import { ProductUsageOverviewTileComponent} from '../product-usage-overview-tile/product-usage-overview-tile.component'
import { TileMagnifierComponent } from '../tile-magnifier/tile-magnifier.component'
import { TileFilterComponent } from '../tile-filter/tile-filter.component';
@Component({
  selector: 'app-clinical-container',
  templateUrl: './clinical-container.component.html',
  styleUrls: ['./clinical-container.component.scss'],
  standalone: true,
  imports:[PreferenceCardAccuracyTileComponent,FinancialOverviewTileComponent,OrScanningTileComponent,ProductsUsageReturnsTileComponent,PreferenceCardOverviewTileComponent,ProductUsageOverviewTileComponent,TileMagnifierComponent,TileFilterComponent]
})
export class ClinicalContainerComponent implements OnInit, OnDestroy {

  public filterConfig: any;
  public financialOverviewFilterConfig: any = {};
  private getFilterPopupState$: Subscription;
  public isDisplayFilter: boolean;
  public orScanningFilterConfig: any = {};
  public preferenceCardAccuracyFilterdata: any;
  public preferenceCardFilterConfig: any = {};
  public productUsageFilterConfig: any = {};
  public showMagnifiedViewPopupData: any;

  constructor(private readonly clinicalService: ClinicalService) { }

  public ngOnInit(): void {
    this.isDisplayFilter = false;
    this.showMagnifiedViewPopupData = { isMagnifiedView: false, tile: '' };
    this.loadTileDataFilters();
    this.getFilterPopupState$ = this.clinicalService.filterPopupState$.subscribe((event) => {
      this.subscribeToPopupState(event);
    });
  }

  private loadTileDataFilters(): void {
    this.clinicalService.getTileDataFilters('Preference Card Accuracy').subscribe((response: FilterApiResponse) => {
      this.clinicalService.setPreferenceCardAccuracyFilter(response.responseData);
      this.preferenceCardFilterConfig = {
        tileName: 'Preference Card Accuracy',
        tileFilters: response.responseData
      };
    });

    this.clinicalService.getTileDataFilters('OR Scanning Accuracy').subscribe((response: FilterApiResponse) => {
      this.clinicalService.setOrScanningFilter(response.responseData);
      this.orScanningFilterConfig = {
        tileName: 'OR Scanning',
        tileFilters: response.responseData
      };
    });

    this.clinicalService.getTileDataFilters('Product%20Usage%20%26%20Returns').subscribe((response: FilterApiResponse) => {
      this.clinicalService.setProductUsageAndReturnsFilter(response.responseData);
      this.productUsageFilterConfig = {
        tileName: 'Product Usage & Returns',
        tileFilters: response.responseData
      };
    });

    this.clinicalService.getTileDataFilters('Financial Overview').subscribe((response: FilterApiResponse) => {
      this.clinicalService.setFinancialOverviewFilter(response.responseData);
      this.financialOverviewFilterConfig = {
        tileName: 'Financial Overview',
        tileFilters: response.responseData
      };
    });
  }

  public ngOnDestroy(): void {
    this.clinicalService.setFilterPopupState(null);
    this.getFilterPopupState$?.unsubscribe();
  }

  public showMagnifiedPopup(event: any): void {
    this.showMagnifiedViewPopupData = event;
  }

  private subscribeToPopupState(event: any): void {
    if (event && event.isDisplayFilter) {
      this.showMagnifiedViewPopupData.tile = '';
      this.isDisplayFilter = event.isDisplayFilter;
      switch (event.tile) {
        case 'Preference Card Accuracy':
          this.filterConfig = this.preferenceCardFilterConfig;
          break;
        case 'OR Scanning Accuracy':
          this.filterConfig = this.orScanningFilterConfig;
          break;
        case 'Product Usage & Returns':
          this.filterConfig = this.productUsageFilterConfig;
          break;
        case 'Financial Overview':
          this.filterConfig = this.financialOverviewFilterConfig;
          break;
      }
    } else {
      this.isDisplayFilter = false;
    }
  }

  public applyFilter(filterData: any): void {
    this.isDisplayFilter = false;
    switch (filterData.tileName) {
      case 'Preference Card Accuracy':
        this.preferenceCardFilterConfig = filterData;
        this.clinicalService.setPreferenceCardAccuracyFilter(filterData.tileFilters);
        break;
      case 'OR Scanning':
        this.orScanningFilterConfig = filterData;
        this.clinicalService.setOrScanningFilter(filterData.tileFilters);
        break;
      case 'Product Usage & Returns':
        this.productUsageFilterConfig = filterData;
        this.clinicalService.setProductUsageAndReturnsFilter(filterData.tileFilters);
        break;
      case 'Financial Overview':
        this.financialOverviewFilterConfig = filterData;
        this.clinicalService.setFinancialOverviewFilter(filterData.tileFilters);
        break;
    }
    this.clinicalService.setFilterPopupState(null);
  }

}
