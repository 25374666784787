import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { ClinicalService } from '../clinical.service';
import { DataPointsInfo, TileFilterInfo } from 'src/app/core/dashboard/models/clinical-dashboard-model.model';
import { AdamConf } from '@app/app.config';
import { LoggerService } from '@app/core/logger.service';
import { AuthService } from '@app/auth/auth.service';
import { StackedBarChartComponent } from '../tile-graphs/stacked-bar-chart/stacked-bar-chart.component';
import { TileMagnifierComponent } from '../tile-magnifier/tile-magnifier.component';

interface ChartDataItem {
  category: string;
  bar1: number;
  bar1ToolTipInfo: any;
  bar2: number;
  bar2ToolTipInfo: any;
  line: number;
  lineToolTipInfo: any;
}

@Component({
  selector: 'app-or-scanning-tile',
  standalone: true,
  imports: [StackedBarChartComponent, TileMagnifierComponent],
  templateUrl: './or-scanning-tile.component.html',
  styleUrls: ['./or-scanning-tile.component.scss']
})
export class OrScanningTileComponent implements OnInit, OnDestroy {
  public isDisplayFilter = false;
  public filterConfig: any;
  private orScanningParams: TileFilterInfo;
  public mtd: number;
  public ytd: number;
  public chartData: ChartDataItem[];
  public chartMetaData: any;
  public bar1Items: any;
  public bar2Items: any;
  public bar3Items: any;
  public lineItems: any;
  public adamLabels = AdamConf;
  private filterSubscription$: Subscription;
  private dataSubscription$: Subscription;
  private ticker$: Subscription;
  @Input() showMagnifiedViewPopupData: any;
  @Output() showMagnifiedPopup = new EventEmitter<string[]>();

  constructor(
    private clinicalService: ClinicalService,
    private router: Router,
    private readonly logger: LoggerService,
    private readonly authService: AuthService
  ) {
  }

  ngOnInit() {
    this.chartData = [];
    this.bar1Items = [];
    this.bar2Items = [];
    this.bar3Items = [];
    this.lineItems = [];
    this.chartMetaData = {
      margin: {
        top: 20,
        right: 30,
        bottom: 50,
        left: 40
      }
    };
    this.loadOrScanningTileData();
    this.setAutoRefresh();
  }

  private setAutoRefresh(): void {
    const currentUser = this.authService.getCurrentUser();
    if (currentUser.isAutoRefreshEnabled === true && this.showMagnifiedViewPopupData.isMagnifiedView !== true) {
      const delayToStartTimer = currentUser.autoRefreshTimeInterval;
      const timerInterval = delayToStartTimer;
      const source = timer(delayToStartTimer, timerInterval);
      this.ticker$ = source.subscribe((val) => {
        this.logger.log('Refreshing OR Scanning Accuracy tile data');
        this.loadOrScanningTileData();
      });
    }
  }

  private loadOrScanningTileData(): void {
    this.resetOrScanningSubscriptions();
    this.filterSubscription$ = this.clinicalService.orScanningFilter$.subscribe((data) => {
      if (data) {
        this.orScanningParams = {
          tileName: 'OR Scanning Accuracy',
          tileFilters: data
        };
        this.getDataByFilters();
      }
    });
  }

  private getDataByFilters(): void {
    if (this.dataSubscription$) {
      this.dataSubscription$.unsubscribe();
    }
    this.dataSubscription$ = this.clinicalService.getClinicalTileData(this.orScanningParams)
      .subscribe((resp) => {
        if (resp.responseCode === '2001') {
          resp.responseData.calloutData.forEach(order => {
            if (order.label === 'MTD') {
              this.mtd = order.value;
            } else if (order.label === 'YTD') {
              this.ytd = order.value;
            }
          });
          if (resp.responseData.dataSeriesCollection) {
            this.bar1Items = this.getDataToDrawGraph(resp.responseData.dataSeriesCollection, this.adamLabels.clinical_module.orScanningBar1Label);
            this.bar2Items = this.getDataToDrawGraph(resp.responseData.dataSeriesCollection, this.adamLabels.clinical_module.orScanningBar2Label);
            this.lineItems = this.getDataToDrawGraph(resp.responseData.dataSeriesCollection, this.adamLabels.clinical_module.orScanningLineLabel);
            this.bar3Items = this.getDataToDrawGraph(resp.responseData.dataSeriesCollection, this.adamLabels.clinical_module.orScanningBar3Label);
          }
          this.setChartData(resp);
        }
        if (resp.responseCode !== '2001') {
          this.mtd = null;
          this.ytd = null;
        }
      });
  }

  private setChartData(resp: any): void {
    if (resp.responseData.categories && resp.responseData.categories.length > 0
      && this.bar1Items.length > 0 && this.bar2Items.length > 0 && this.lineItems.length > 0 && this.bar3Items.length > 0) {
      let tempArr = [];
      for (let i = 0; i < resp.responseData.categories.length; i++) {
        const tempChartItem = {
          category: resp.responseData.categories[i],
          bar1: this.bar1Items[i].value,
          bar1ToolTipInfo: this.bar1Items[i].toolTipInfo,
          bar2: this.bar2Items[i].value,
          bar2ToolTipInfo: this.bar2Items[i].toolTipInfo,
          bar3: this.bar3Items[i].value,
          bar3ToolTipInfo: this.bar3Items[i].toolTipInfo,
          line: this.lineItems[i].value,
          lineToolTipInfo: this.lineItems[i].toolTipInfo,
          total: 0,
          values: [],
        };
        tempArr.push(tempChartItem);
      }
      this.chartData = tempArr;
    }
  }

  private getDataToDrawGraph(data: any, axes: string): DataPointsInfo[] {
    let dataItems = [];
    const item0 = 0, item1 = 1, item2 = 2, item3 = 3;
    switch (axes) {
      case data[item0].legend: {
        dataItems = data[item0].dataPoints;
        break;
      }
      case data[item1].legend: {
        dataItems = data[item1].dataPoints;
        break;
      }
      case data[item2].legend: {
        dataItems = data[item2].dataPoints;
        break;
      }
      case data[item3].legend: {
        dataItems = data[item3].dataPoints;
        break;
      }
    }
    return dataItems;
  }

  public showMagnifiedView(): void {
    this.showMagnifiedViewPopupData.isMagnifiedView = (this.showMagnifiedViewPopupData.isMagnifiedView === true) ? false : true;
    this.showMagnifiedViewPopupData.tile =
      (this.showMagnifiedViewPopupData.isMagnifiedView === true) ? this.adamLabels.clinical_module.orScanningLabel : '';
    this.showMagnifiedPopup.emit(this.showMagnifiedViewPopupData);
  }

  public displayFilter(): void {
    this.isDisplayFilter = true;
    this.filterConfig = {
      isDisplayFilter: this.isDisplayFilter,
      tile: 'OR Scanning Accuracy'
    };
    this.clinicalService.setFilterPopupState(this.filterConfig);
    this.showMagnifiedViewPopupData.isMagnifiedView = false;
  }

  public ngOnDestroy(): void {
    this.resetOrScanningSubscriptions();
    if (this.ticker$) {
      this.ticker$.unsubscribe();
    }
  }

  private resetOrScanningSubscriptions(): void {
    if (this.dataSubscription$) {
      this.dataSubscription$.unsubscribe();
    }
    if (this.filterSubscription$) {
      this.filterSubscription$.unsubscribe();
    }
  }

  public navigateToORScanningOverview(): void {
    this.router.navigate(['/insights/or-scanning/overview/', 'all']);
  }

}
