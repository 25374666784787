import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, ElementRef, ViewChild, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as D3 from 'd3';
import { ChartConfig, PieChartDataModel } from 'src/app/shared/models/pie-chart.model';
import { MousePosition } from '@app/shared/models/line-chart.model';


@Component({
  selector: 'app-pie-chart-view',
  standalone: true,
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
  providers: [CurrencyPipe],
  imports: [CommonModule]
})
export class PieChartComponent implements AfterViewInit, OnChanges {
  @ViewChild('containerPieChart', { static: false }) element!: ElementRef;

  @Input() title!: string;
  @Input() dataFormat = 'number';
  @Input() chartConfig: ChartConfig = { margin: { top: 2, bottom: 2, right: 2, left: 2 } };
  @Input() textColor = '#000';
  @Input() pieData!: PieChartDataModel[];

  private host!: D3.Selection<HTMLElement, unknown, null, undefined>;
  private svg!: D3.Selection<SVGGElement, unknown, null, undefined>;
  private radius!: number;
  private htmlElement!: HTMLElement;
  private width = 200;
  private height = 200;

  constructor(private currencyPipe: CurrencyPipe) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['pieData'] && !changes['pieData'].isFirstChange()) {
      this.pieData = changes['pieData'].currentValue;
      this.setup();
      this.buildSVG();
      this.buildPie();
    }
  }

  ngAfterViewInit() {
    this.width = this.element.nativeElement.offsetWidth - this.chartConfig.margin.left - this.chartConfig.margin.right;
    this.height = this.element.nativeElement.offsetHeight - this.chartConfig.margin.top - this.chartConfig.margin.bottom;
    this.htmlElement = this.element.nativeElement;
    this.host = D3.select(this.htmlElement);
    this.setup();
    this.buildSVG();
    this.buildPie();
  }

  private setup(): void {
    this.radius = Math.min(this.width, this.height) / 2;
  }

  private buildSVG(): void {
    if (this.host) {
      this.host.html('');
      this.svg = this.host
        .append<SVGSVGElement>('svg')
        .attr('viewBox', `0 0 ${this.width} ${Math.max(this.height, 0)}`)
        .append<SVGGElement>('g')
        .attr('transform', `translate(${this.width / 2},${this.height / 2})`);
    }
  }

  private buildPie(): void {
    if (!this.svg) {
      console.error('SVG element is not initialized');
      return;
    }
    const pie = D3.pie<PieChartDataModel>().value(d => d.value);
    if (this.pieData) {
      const arcSelection = this.svg
        .selectAll<SVGGElement, D3.PieArcDatum<PieChartDataModel>>('.arc')
        .data(pie(this.pieData))
        .enter()
        .append<SVGGElement>('g')
        .attr('class', 'arc')
        .on('mouseover', (event, d) => {
          const tooltipData = this.pieData.filter(item => item.tooltipData.length === 0);
          if (tooltipData.length === 0) {
            const duration = 200;
            const opacity = 0.9;
            const topAdj = 28;
            const div = D3.select('body').append('div')
              .attr('class', 'tooltip-chart-line')
              .style('opacity', 0);
            div.transition()
              .duration(duration)
              .style('opacity', opacity);
            div.html(this.getTooltipContent(d))
              .style('left', () => {
                const leftAdj = 10;
                const pos = this.positionTooltip(
                  {
                    x: event.pageX,
                    y: event.pageY
                  });
                return (pos.left + leftAdj) + 'px';
              })
              .style('top', (event.pageY - topAdj) + 'px')
              .style('z-index', '10001');
          }
        })
        .on('mouseout', () => {
          const tooltip = document.getElementsByClassName('tooltip-chart-line');
          if (tooltip[0] && tooltip[0].parentNode) {
            tooltip[0].parentNode.removeChild(tooltip[0]);
          }
          const duration = 500;
          const div = D3.select('tooltip-chart-line');
          div.transition()
            .duration(duration)
            .style('opacity', 0);
        });
      this.populatePie(arcSelection);
    }
  }

  private populatePie(arcSelection: D3.Selection<SVGGElement, D3.PieArcDatum<PieChartDataModel>, SVGGElement, unknown>): void {
    const pieSizeIndex = 5;
    const outerRadius = this.radius - pieSizeIndex;
    const arc = D3.arc<D3.PieArcDatum<PieChartDataModel>>()
      .innerRadius(0)
      .outerRadius(outerRadius);
    arcSelection
      .append<SVGPathElement>('path')
      .attr('d', arc)
      .attr('fill', (d, index) => {
        return this.pieData[index].color;
      });

    arcSelection
      .append<SVGTextElement>('text')
      .attr('transform', (d) => {
        return 'translate(' + arc.centroid(d) + ')';
      })
      .text((d, index) => this.setDataFormat(this.pieData[index].label))
      .style('text-anchor', 'middle')
      .style('fill', this.textColor)
      .style('font-size', this.radius / 8);
  }

  private getTooltipContent(d: D3.PieArcDatum<PieChartDataModel>): string {
    const toolTip = this.pieData.filter(item => item.value === d.data.value);
    let tooltipContent = '';
    tooltipContent = tooltipContent + '<table class="graph-table">';
    if (toolTip.length > 0) {
      toolTip[0].tooltipData.forEach((element: { label: any; value: any; }) => {
        tooltipContent += `<tr><td class="title">${element.label}:</td><td class="data-value">${element.value}</td></tr>`;
      });
    }
    tooltipContent = tooltipContent + '</table>';
    return tooltipContent;
  }

  private positionTooltip(mouse: { x: any; y: any; }): MousePosition {
    const box = document.querySelector('.graph-table');
    if (!box) {
      return { x: mouse.x, y: mouse.y, top: mouse.y, left: mouse.x }; // Return default position if box is null
    }
    const style = getComputedStyle(box);
    const toolwidth = parseInt(style.width);
    const toolMaxWidth = 100;
    if (window.innerWidth - (mouse.x + toolwidth) < toolMaxWidth) {
      const mouseAdj = 30;
      mouse.x = mouse.x - toolwidth - mouseAdj;
    }
    return {
      x: mouse.x,
      y: mouse.y,
      top: mouse.y,
      left: mouse.x
    };
  }
  
  private setDataFormat(label: string): string {
    let returnLabel = '';
    if (this.dataFormat === 'currency') {
      returnLabel = this.currencyPipe.transform(label, 'USD', 'symbol', '1.0-0') ?? '';
    } else if (this.dataFormat === 'percentage' && label !== '' && label !== '0') {
      returnLabel = label + '%';
    } else if (this.dataFormat === 'percentage' && label === '0') {
      returnLabel = '';
    } else {
      returnLabel = label;
    }
    return returnLabel;
  }
}
