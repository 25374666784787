<header *ngIf='activeHeader'>
  <app-header [activeHeader]="activeHeader" [minimalHeader]="minimalHeader" [authenticated]="authenticated" [opsAuthenticated]="opsAuthenticated" [showLogo]="showLogo" [currentModule]="currentModule" [isOpsHeader]="isOpsHeader" (searchNavToggled)="updateSearchNav($event)"></app-header>
</header>
<main class="container-fluid" [ngClass]="{
  'jjgraybg': (greybg),
  'jjfullheight':(!activeFooter && !activeHeader), 
  'topPadding' : (showLogo && !minimalHeader),
  'printPadding': (printStyle),
  'search-nav-visible':searchNav 
}">
  <!-- <ng-http-loader [entryComponent]="globalSpinnerComponent" [filteredUrlPatterns]="loaderExclusions"></ng-http-loader> -->
  <router-outlet></router-outlet>
  <app-p-dialog></app-p-dialog>
</main>

<footer>
  <app-footer *ngIf='activeFooter' [isOps]="isOpsHeader"></app-footer>
</footer>