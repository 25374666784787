<div class="card yest-tile">
  <div class="card-body text-center">
    <div class="card-title-wrapper clearfix mt-1">
      <span class="card-item-title float-start">Preference Card Overview</span>
    </div>
    <div class="card-kpi-list">
      @for (data of preferenceCardData; track data) {
        <div class="cart-box-border mb-4">
          <div class="card-sub-title clearfix" (click)="navigateToPreferenceCardDetails(data.routerParams)">
            <span class="float-start" [class.total]="data.totalItem">{{data.key}}</span>
            <span class="card-item-value float-end">{{data.value | number:'1.0-2':'en-US'}}</span>
          </div>
        </div>
      }
    </div>
  </div>
</div>
