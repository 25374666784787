import { Injectable } from '@angular/core';
import { BaseService } from '../base.service'
import { Observable, BehaviorSubject } from 'rxjs';
import { SearchBarAutoCompleteApiInfo, SearchQueryParam, SearchProductsApiResponse } from '../models/header.model';

@Injectable({
  providedIn: 'root'
})
export class SearchServiceService {

  public _searchSubject: BehaviorSubject<Object> = new BehaviorSubject<Object>(1);

  constructor(private baseService: BaseService) { }

  getSearchObs(): Observable<any> {
    return this._searchSubject.asObservable();
  }
  setSearchObs(searchData: any) {
    this._searchSubject.next(searchData);
  }

  getProductsForAutoComplete( searchQuery: string, origin: string | null): Observable<SearchBarAutoCompleteApiInfo> | undefined{
    let encodedSearchQuery = encodeURIComponent(searchQuery.replace(/[^a-z0-9-,. ]/gi, ''));
    if (encodedSearchQuery != "") {
      return this.baseService.getViaParam("inventory-management/api/v1/public/product/search/requestOrigin/" + origin+ "/prodNameOrProdCode/" + encodedSearchQuery, {});
    }
    return;
  }
  getProductResults( searchQuery: SearchQueryParam, pageIndex: number, origin: string): Observable<SearchProductsApiResponse> | undefined {
    let encodedSearchQuery = encodeURIComponent(searchQuery.query.replace(/[^a-z0-9-,. ]/gi, '').toLowerCase());
    let searchQueryStatus = searchQuery.status.toLowerCase();
    if (encodedSearchQuery != "") {
       return this.baseService.getViaParam("search-framework/api/v1/public/product/search/requestOrigin/"+ origin + "/inventory '" + encodedSearchQuery + "'/" + searchQueryStatus + "?pageIndex=" + pageIndex, {});
    }

  }

  getProductsForAutoCompleteAuthorized( searchQuery: string): Observable<SearchBarAutoCompleteApiInfo> | undefined{
    let encodedSearchQuery = encodeURIComponent(searchQuery.replace(/[^a-z0-9-,. ]/gi, ''));
    if (encodedSearchQuery != "") {
      return this.baseService.getViaParam("inventory-management/api/v1/product/search/prodNameOrProdCode/" + encodedSearchQuery, {});
    }
  }
  getProductResultsAuthorized( searchQuery: SearchQueryParam, pageIndex: number): Observable<SearchProductsApiResponse> | undefined{
    let encodedSearchQuery = encodeURIComponent(searchQuery.query.replace(/[^a-z0-9-,. ]/gi, '').toLowerCase());
    let searchQueryStatus = searchQuery.status.toLowerCase();
    if (encodedSearchQuery != "") {
       return this.baseService.getViaParam("search-framework/api/v1/product/search/inventory ''" + encodedSearchQuery + "'/" + searchQueryStatus + "?pageIndex=" + pageIndex, {});
    }

  }

  getProductsForAutoCompleteOps(institutionId, facilityId, searchQuery:string) {
    let encodedSearchQuery = encodeURIComponent(searchQuery.replace(/[^a-z0-9,. ]/gi, ''));
    return this.baseService.getViaParam("institution-management/api/v1/institutions/{institutionId}/facilities/list" + encodedSearchQuery, {});
  }
}
