import { Component, OnInit, Output, EventEmitter, Input,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropDownParamInfo, FilterListInfo, FilterValueItem, TileFilterInfo } from '../../models/materials-management-dashboard.model';
import { MaterialsManagementService } from '../materials-management.service';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { InventoryTileComponent} from 'src/app/core/dashboard/materials-management/inventory-tile/inventory-tile.component'
import { SimpleChanges, OnChanges } from '@angular/core';


@Component({
  selector: 'app-materials-management-tile-filter',
  standalone: true,
  imports: [CommonModule,FormsModule, DialogModule,MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,InventoryTileComponent,],
  templateUrl: './materials-management-tile-filter.component.html',
  styleUrls: ['./materials-management-tile-filter.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MaterialsManagementTileFilterComponent implements OnInit {
  public display: boolean = true;
  public showMessage: boolean = false;
  public errorMessage: string = '';
  @Input() filterConfig: TileFilterInfo;
  @Output() appliedFilter: EventEmitter<any> = new EventEmitter();
  @Output() closeFilter: EventEmitter<any> = new EventEmitter();
  public filterList: TileFilterInfo;
  private dropDownParams: DropDownParamInfo;
  public maxDate: Date = new Date();
  public disableRangeSelection: boolean = false;

  constructor(private readonly service: MaterialsManagementService) {
    
    this.dropDownParams = {
      'search': '',
      'surgeon': [],
      'specialty': [],
      'procedureType': [],
    };
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterConfig && this.filterConfig) {
      console.log("Filter Config changed:", this.filterConfig);
      this.filterList = JSON.parse(JSON.stringify(this.filterConfig));
      // Additional processing...
    }
  }
  public ngOnInit(): void {
    console.log("MaterialsManagementTileFilterComponent initialized");

    this.filterList = JSON.parse(JSON.stringify(this.filterConfig));
    console.log("At Filter", this.filterList);
    this.filterList.tileFilters.forEach(filter => {
      if (filter.type === 'NumberRange') {
        this.setFilterforNumberRange(filter);
      } else if (filter.type === 'RadioList') {
        if (filter['selectedValues']) {
          filter.selectedValue = filter.values.find(x => x.name === filter['selectedValues'][0]);
        }
        this.radioFilterChange(filter);
      } else if (filter.type === 'Checkbox' && filter['selectedValues']) {
        filter.selectedValue = [];
        filter['selectedValues'].forEach(item => {
          filter.selectedValue.push(filter.values.find(x => x.name === item));
        });
        this.checkboxFilterChange(filter);
      } else if (filter.type === 'DateRange' && filter['selectedValues']) {
        filter.from = filter['selectedValues'][0] ? new Date(filter['selectedValues'][0]) : '';
        filter.to = filter['selectedValues'][0] ? new Date(filter['selectedValues'][1]) : '';
      } else if (filter.type === 'SearchBox') {
        this.dropdownFilterChange(filter);
      }
    });
  }

  private setFilterforNumberRange(filter: FilterListInfo): void {
    if (filter['selectedValues']) {
      filter['selectedValue'] = filter['selectedValues'];
    } else {
      filter['selectedValue'] = [filter.minValue, filter.maxValue];
    }
  }

  public radioFilterChange(filter: FilterListInfo): void {
    if (filter.selectedValue.id === 'Date Range') {
      this.disableRangeSelection = false;
    } else {
      let dateFilter = this.filterList.tileFilters.find(x => x.type === 'DateRange');
      if (dateFilter) {
        dateFilter.from = '';
        dateFilter.to = '';
      }
      this.disableRangeSelection = true;
    }
  }

  public checkboxFilterChange(filter: FilterListInfo): void {
    if (filter.fieldName === 'Specialty') {
      let data: {
        search: string;
        surgeon: string[];
        specialty: string[];
        procedureType: string[];
      } = {
        search: '',
        surgeon: this.dropDownParams.surgeon ? this.dropDownParams.surgeon : [],
        specialty: [],
        procedureType: this.dropDownParams.procedureType ? this.dropDownParams.procedureType : []
      };
      filter.selectedValue.forEach(item => {
        data.specialty.push(item.id.toString());
      });
      this.dropDownParams = data;
    }
  }
  

  public clearFilter(filter: FilterListInfo): void {
    filter.selectedValue = null;
    this.dropdownFilterChange(filter);
  }

  public filterQueryChange(event: any, filter: FilterListInfo): void {
    if (filter.fieldName !== 'Brand') {
      this.dropDownParams['search'] = event.query;
      this.fetchFilteredDropdownItems(this.dropDownParams, filter);
    } else {
      const reqParams = {
        search: event.query,
        product: [],
        needleType: [],
        needleName: []
      };
      this.fetchFilteredDropdownItems(reqParams, filter);
    }
  }

  public dropdownFilterChange(filter: FilterListInfo): void {
    if (filter.fieldName === 'Surgeon') {
      let surgeon: string[] = [];
      if (filter.selectedValue) {
        filter.selectedValue.forEach(item => {
          surgeon.push(item.id);
        });
      }
      this.dropDownParams['surgeon'] = surgeon;
    }
    if (filter.fieldName === 'Procedure Type') {
      let procedureType: string[] = [];
      if (filter.selectedValue) {
        filter.selectedValue.forEach(item => {
          procedureType.push(item.id);
        });
      }
      this.dropDownParams['procedureType'] = procedureType;
    }
  
    this.dropDownParams['search'] = '';
    let reqData = JSON.parse(JSON.stringify(this.dropDownParams));
    this.filterList.tileFilters.forEach(filterItem => {
      if (filterItem.fieldName === 'Specialty') {
        reqData['specialty'] = [];
        let selected = JSON.parse(JSON.stringify(filterItem.selectedValue ? filterItem.selectedValue : []));
        this.fetchFilteredDropdownItems(reqData, filterItem, selected);
      }
    });
  }
  

  private fetchFilteredDropdownItems(data: DropDownParamInfo, filter: FilterListInfo, selected?: FilterValueItem[]): void {
    const strPos = 2;
    if (filter.remoteLink) {
      this.service.fetchTileFilteredDropdownList(filter.remoteLink.substring(strPos), data).subscribe(
        res => {
          filter.values = res.responseData;
          if (selected) {
            filter.selectedValue = [];
            selected.forEach(item => {
              filter.values.forEach(valueItem => {
                if (item.id === valueItem.id) {
                  filter.selectedValue.push(valueItem);
                }
              });
            });
          }
        });
    } else {
      console.error('filter.remoteLink is null or undefined');
    }
  }
  
  public dateFromChanged(filter: FilterListInfo): void {
    if (filter.to && filter.from && filter.to !== '' && (+new Date(filter.to) < +new Date(filter.from))) {
      filter.to = '';
    }
  }
  

  public validateDateRange(): void {
    let isTimeperiod = this.filterList.tileFilters.find(tile => tile.fieldName === 'Time Period');
    if (isTimeperiod && isTimeperiod.selectedValue.id === 'Date Range') {
      let filter = this.filterList.tileFilters.find(tile => tile.type === 'DateRange');
      if (filter) {
        this.setValidationErrorMessage(filter);
      } else {
        this.applyFilter();
      }
    } else {
      this.applyFilter();
    }
  }

  private setValidationErrorMessage(filter: any): void {
    if (!filter.from && !filter.to) {
      this.errorMessage = 'Date Range should be selected';
    } else if (filter.from && !filter.to) {
      this.errorMessage = `Date Range 'To' should be selected`;
    } else if (!filter.from && filter.to) {
      this.errorMessage = `Date Range 'From' should be selected`;
    } else {
      this.errorMessage = '';
    }
    if (this.errorMessage === '') {
      this.applyFilter();
    } else {
      this.showMessage = true;
    }
  }
  private applyFilter(): void {
    let emitData: {
      tileName: string;
      tileFilters: FilterListInfo[];
    } = {
      tileName: this.filterList.tileName,
      tileFilters: []
    };
  
    this.filterList.tileFilters.forEach(filter => {
      let data: FilterListInfo = { ...filter, selectedValues: [] };
      if (filter.type === 'DateRange') {
        if (filter.from) {
          data.selectedValues.push((new Date(filter.from).getFullYear() + '-' +
            (new Date(filter.from).getMonth() + 1) + '-' +
            new Date(filter.from).getDate()).toString());
        }
        if (filter.to) {
          data.selectedValues.push((new Date(filter.to).getFullYear() + '-' +
            (new Date(filter.to).getMonth() + 1) + '-' +
            new Date(filter.to).getDate()).toString());
        }
      } else if ((filter.type === 'RadioList') && filter.selectedValue) {
        data.selectedValues.push(filter.selectedValue.name);
      } else if (filter.type === 'NumberRange' && filter.selectedValue) {
        data.selectedValues.push(filter.selectedValue[0].toString());
        data.selectedValues.push(filter.selectedValue[1].toString());
      } else {
        if (filter.selectedValue) {
          filter.selectedValue.forEach(value => {
            data.selectedValues.push(value.name);
          });
        }
      }
      emitData.tileFilters.push(data);
    });
  
    this.appliedFilter.emit(emitData);
  }
  
  public close(): void {
    this.filterList.tileFilters.forEach(filter => {
      if (!filter.selectedValues) {
        if (filter.type === 'DateRange') {
          filter.from = '';
          filter.to = '';
        }
        delete filter.selectedValue;
      }
    });
    this.closeFilter.emit({});
  }
}
