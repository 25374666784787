<div class="inventory-tile-container">
  <div class="row header">
    <div class="col-md-5">
      <span class="card-heading">{{ adamLabels.materialManagement_module.inventoryTileName }}</span>
      <span class="pt-2">
        <i class="material-icons pointer" (keydown)="navigateToInventoryLevels()" (click)="navigateToInventoryLevels()">info_outline</i>
      </span>
    </div>
    <div class="col-md-3 ms-3 mt-3">
      <div class="filter-expand float-end">
        <div class="filter-container" (keydown)="displayFilter()" (click)="displayFilter()">
          <p class="filter-text">Filters <i class="material-icons filter-icon">filter_list</i></p>
        </div>
        <div class="display" (keydown)="showMagnifiedView()" (click)="showMagnifiedView()" *ngIf="!showMagnifiedViewPopupData.isMagnifiedView">
          <img class="expand-img" src="./assets/img/maximize_icon.png" alt="Expand image" />
        </div>
        <div class="display" (keydown)="showMagnifiedView()" (click)="showMagnifiedView()" *ngIf="showMagnifiedViewPopupData.isMagnifiedView">
          <img class="expand-img" src="./assets/img/minimize_icon.png" alt="Expand image" />
        </div>
      </div>
    </div>
  </div>
  <div class="sub-head">
    <span *ngFor="let item of selectedOptions; let i = index;">{{ item }}
      <span *ngIf="selectedOptions.length > 1 && i != selectedOptions.length - 1">, </span>
    </span>
  </div>

  <div class="row inventory-tile-body">
    <div class="col-xl-2 col-lg-2 col-md-3 mb-4 column">
      <ng-container *ngIf="inventoryStackData">
        <div class="inventory-tile-kpi-box" *ngFor="let callOut of inventoryStackData.calloutData">
          <p class="box-key mt-3">{{ callOut.label }}:</p>
          <p class="box-value">{{ callOut.firstDetailValue }}</p>
        </div>
      </ng-container>
    </div>
    <div class="col-xl-10 col-lg-8 col-md-6 column chart-container">
      <div class="inventory-pie-one">
        <div class="pie-container mt-3">
          <app-pie-chart-view [title]="'Stack Count'" [textColor]="'#FFF'" [pieData]="stackCountPieChartData"></app-pie-chart-view>
        </div>
        <div class="legend-container">
          <ul class="inventory-legend stack-count">
            <ng-container *ngIf="inventoryStackData">
              <li *ngFor="let legend of inventoryStackData.categories">
                <div class="legend-color {{ legend | lowercase }}"></div>
                <div class="legend-text">{{ legend }}</div>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
      <div class="inventory-pie-two">
        <div class="pie-container mt-3">
          <app-pie-chart-view [title]="'Inventory Value'" [dataFormat]="'currency'" [textColor]="'#FFF'" [pieData]="valuePieChartData"></app-pie-chart-view>
        </div>
        <div class="legend-container">
          <ul class="inventory-legend inventory-value">
            <ng-container *ngIf="inventoryValueData">
              <li *ngFor="let legend of inventoryValueData.categories">
                <div class="legend-color {{ legend | lowercase }}" *ngIf="legend !== 'Smart Shelf'"></div>
                <div class="legend-color smart-shelf" *ngIf="legend === 'Smart Shelf'"></div>
                <div class="legend-text">{{ legend }}</div>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>

 
</div>
