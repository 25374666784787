import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { AuthService } from '../auth/auth.service';
import { OpsAuthService } from '../ops/ops-auth.service';
import { AdamopsConf } from '../ops/ops-config';
import { LoggerService } from './logger.service';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { AdamConf } from '@app/app.config';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class TokenHandlerInterceptor implements HttpInterceptor {
  private appMode: any = '';
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private readonly authService: AuthService,
    private readonly opsAuthService: OpsAuthService,
    private readonly logger: LoggerService,
    private readonly storageService: StorageService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('oauth/token') !== -1 || request.url.indexOf('sso/token') !== -1) {
      request = request.clone({
        setHeaders: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': AdamConf.basicAuthToken
        }
      });
      return next.handle(request);
    }

    // Change to request.url.indexOf('ops') when /ops gets added to the API's
    this.appMode = (location.href.indexOf('ops') !== -1) ? 'ops' : 'aita';

    // token check and appending to header before each api calling
    if (this.appMode === 'ops') {
      return this.opsUserRequestHandler(request, next)
    } else if (this.appMode === 'aita') {
      return this.aitaUserRequestHandler(request, next)
    }
    return next.handle(request);
  }
  private opsUserRequestHandler(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Handle auth token for OPS user
    const opsUser = this.opsAuthService.getOpsCurrentUser();
    if (opsUser && request.url.indexOf('logout') === -1) {
      const timeLeftForTokenExpiry = this.getTimeLeftToTokenExpiry();
      
      if (timeLeftForTokenExpiry < AdamopsConf.minTimeLeftToRefreshToken && timeLeftForTokenExpiry > 0) {
        if (this.refreshTokenInProgress) {
          // delay api calls until refresh token is complete
          this.logger.log('Delaying ' + request.url + ' until token is refreshed');
          return this.refreshTokenSubject.pipe(
            filter(result => result !== null),
            take(1),
            switchMap(() => next.handle(this.addAuthTokenToRequest(request)))
          );
        } else {
          this.logger.log('Refreshing token, only ' + (timeLeftForTokenExpiry / 1000) + ' secs left for token expiry');
          this.refreshTokenInProgress = true;
          this.refreshTokenSubject.next(null);
          return this.opsAuthService.getRefreshToken()
            .pipe(
              switchMap((data) => {
                this.opsAuthService.setSessionValues(data);
                request = this.addOpsAuthTokenToRequest(request);
                this.refreshTokenSubject.next(data);
                return next.handle(request);
              }),
              catchError((error) => {
                this.logger.error('Refresh token failed, appending old token this time');
                request = this.addOpsAuthTokenToRequest(request);
                this.refreshTokenSubject.next(error);
                return next.handle(request);
              }),
              finalize(() => this.refreshTokenInProgress = false)
            );
        }
      } else {
        request = this.addOpsAuthTokenToRequest(request);
        return next.handle(request);
      }
    } else {
      return next.handle(request);
    }
  }

  private aitaUserRequestHandler(request: HttpRequest<any>, next: HttpHandler) {
    // Handle auth token for AITA user
    const currentUserToken = this.storageService.getItem('currentUserToken');
    const aitaUser = currentUserToken ? JSON.parse(currentUserToken) : null;
    // const aitaUser = this.authService.getCurrentUser();
    
    
    
    if (aitaUser && request.url.indexOf('logout') === -1) {
      const timeLeftForTokenExpiry = this.getTimeLeftToTokenExpiry();
      if (timeLeftForTokenExpiry < AdamConf.timeLeftToRefreshToken && timeLeftForTokenExpiry > 0) {
        if (this.refreshTokenInProgress) {
          // delay api calls until refresh token is complete
          this.logger.log('Delaying ' + request.url + ' until token is refreshed');
          return this.refreshTokenSubject.pipe(
            filter(result => result !== null),
            take(1),
            switchMap(() => next.handle(this.addAuthTokenToRequest(request)))
          );
        } else {
          this.logger.log('Refreshing token, only ' + (timeLeftForTokenExpiry / 1000) + ' secs left for token expiry');
          this.refreshTokenInProgress = true;
          this.refreshTokenSubject.next(null);
          return this.authService.getRefreshToken()
            .pipe(
              switchMap((data) => {
                this.authService.setSessionValues(data);
                this.authService.getAutoRefreshPreferences().subscribe((autoRefreshApiResponse) => {
                  if (autoRefreshApiResponse.responseCode === '2001') {
                    this.authService.addAutoRefreshPreferenceInSession(autoRefreshApiResponse.responseData);
                  }
                });
                request = this.addAuthTokenToRequest(request);
                this.refreshTokenSubject.next(data);
                return next.handle(request);
              }),
              catchError((error) => {
                this.logger.error('Refresh token failed, appending old token this time');
                request = this.addAuthTokenToRequest(request);
                this.refreshTokenSubject.next(error);
                return next.handle(request);
              }),
              finalize(() => this.refreshTokenInProgress = false)
            );
        }
      } else {
        request = this.addAuthTokenToRequest(request);
        return next.handle(request);
      }
    } else {
      return next.handle(request);
    }
  }

  private addAuthTokenToRequest(request: HttpRequest<any>): HttpRequest<any> {
    const currentUserToken = this.storageService.getItem('currentUserToken');
    const currentUser = currentUserToken ? JSON.parse(currentUserToken) : null;


    // const currentUser = this.authService.getCurrentUser();
    
    const token = currentUser?.access_token;
    return request.clone({
      setHeaders: {
        'Authorization': token || ''
      }
    });
  }

  private addOpsAuthTokenToRequest(request: HttpRequest<any>): HttpRequest<any> {
    const currentUser = this.opsAuthService.getOpsCurrentUser();
    const token = currentUser.access_token;
    return request.clone({
      setHeaders: {
        'Authorization': token
      }
    });
  }

  private getTimeLeftToTokenExpiry(): number {
    const sessionExpTime = (this.appMode === 'ops') ? Number(this.opsAuthService.getExpiry()) : Number(this.authService.getExpiry());
    const curTime = new Date();
    const curTimeinSec = curTime.setSeconds(curTime.getSeconds());
    return sessionExpTime - curTimeinSec;

  }

}
