import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  [x: string]: any;

  constructor() {
    //constructor
   }

  public log(value: string, ...rest: any[]): void {
    const currentTime = new Date();
    if ( !environment.production && environment.enableLog === true) {
      console.log(currentTime.toLocaleTimeString() + ' =>', value, rest);
    }
  }

  public warn(value: string, ...rest: any[]): void {
    const currentTime = new Date();
    if ( !environment.production && environment.enableLog === true) {
      console.warn(currentTime.toLocaleTimeString() + ' =>', value, rest);
    }
  }

  public error(value: string, ...rest: any[]): void {
    const currentTime = new Date();
    if ( !environment.production && environment.enableLog === true) {
      console.error(currentTime.toLocaleTimeString() + ' =>', value, rest);
    }
  }
}
