import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdamConf } from '@app/app.config';
import { Subscription } from 'rxjs';
import { MagnifiedViewModel, FilterConfig, FilterStateConfig, TileFilter, TileDataFilter } from '../../models/materials-management-dashboard.model';
import { MaterialsManagementService } from '../materials-management.service';
import { StorageService } from 'src/app/core/storage.service';
import { InventoryTileComponent} from 'src/app/core/dashboard/materials-management/inventory-tile/inventory-tile.component'
import {TodayProgressTrackerTileComponent} from 'src/app/core/dashboard/materials-management/today-progress-tracker-tile/today-progress-tracker-tile.component'
import {SummaryTileComponent} from 'src/app/core/dashboard/materials-management/summary-tile/summary-tile.component'
import { DispensedRestockedTileComponent} from 'src/app/core/dashboard/materials-management/dispensed-restocked-tile/dispensed-restocked-tile.component'
import {BoxesOrderedReplenishedTileComponent} from 'src/app/core/dashboard/materials-management/boxes-ordered-replenished-tile/boxes-ordered-replenished-tile.component'
import {MaterialsManagementTileFilterComponent} from 'src/app/core/dashboard/materials-management/materials-management-tile-filter/materials-management-tile-filter.component'
import {PickingSummaryTileComponent} from 'src/app/core/dashboard/materials-management/picking-summary-tile/picking-summary-tile.component'
import {MaterialsManagementTileMagnifierComponent} from 'src/app/core/dashboard/materials-management/materials-management-tile-magnifier/materials-management-tile-magnifier.component'

@Component({
  selector: 'app-core-dashboard-materials-management-container',
  templateUrl: './materials-management-container.component.html',
  styleUrls: ['./materials-management-container.component.scss'],
  standalone: true,
  imports:[InventoryTileComponent,TodayProgressTrackerTileComponent,SummaryTileComponent,DispensedRestockedTileComponent,BoxesOrderedReplenishedTileComponent,MaterialsManagementTileFilterComponent,PickingSummaryTileComponent,MaterialsManagementTileMagnifierComponent]
})
export class MaterialsManagementContainerComponent implements OnInit, OnDestroy {
  public displayRestocking: string;
  public displayReplenishment: string;
  public displayOrdering: string;
  public materialManagementTiles: string[];
  public tileFilterSubscriptions$: Subscription[];
  public showMagnifiedViewPopupData: MagnifiedViewModel;
  public dispensedRestockFilterConfig: FilterConfig;
  public inventoryFilterConfig: FilterConfig;
  public filterConfig: FilterConfig;
  public isDisplayFilter: boolean;
  public adamLabels = AdamConf;
  public getFilterPopupState$: Subscription;
  public boxesOrderedReplenishedFilterConfig: FilterConfig;
  public displayTransfers: string;

  constructor(
    private materialManagementService: MaterialsManagementService,
    private readonly storageService: StorageService
  ) { }

  public ngOnInit(): void {
    console.log('at container');
    this.displayRestocking = 'Restocking';
    this.displayReplenishment = 'Replenishment';
    this.displayOrdering = 'Ordering';
    this.displayTransfers = 'Transfers';
    this.materialManagementTiles = [
      this.adamLabels.materialManagement_module.inventoryTileName,
      this.adamLabels.materialManagement_module.boxesOrderedReplenishedTileName,
      this.adamLabels.materialManagement_module.dispensedRestockedTileName
    ];
    this.isDisplayFilter = false;
    this.showMagnifiedViewPopupData = { isMagnifiedView: false, tile: '' };
    this.loadDefaultFilters();
  }

  private loadDefaultFilters(): void {
    let subscription: Subscription;
    this.tileFilterSubscriptions$ = [];
    this.materialManagementTiles.forEach((materialManagementTileName) => {
      subscription = this.materialManagementService.getTileDataFilters(materialManagementTileName).subscribe((response) => {
        this.materialManagementService.setTileDataFilters(materialManagementTileName, response.responseData);
        const transformedResponseData = response.responseData.map((data: TileDataFilter) => ({
          ...data,
          minValue: data.minValue ?? 0, // Ensure minValue is a number
          maxValue: data.maxValue ?? 0  // Ensure maxValue is a number
        })) as TileFilter[];
        switch (materialManagementTileName) {
          case this.adamLabels.materialManagement_module.dispensedRestockedTileName:
            this.dispensedRestockFilterConfig = this.setFilterConfigurations(materialManagementTileName, transformedResponseData);
            break;
          case this.adamLabels.materialManagement_module.inventoryTileName:
            this.inventoryFilterConfig = this.setFilterConfigurations(materialManagementTileName, transformedResponseData);
            break;
          case this.adamLabels.materialManagement_module.boxesOrderedReplenishedTileName:
            this.boxesOrderedReplenishedFilterConfig = this.setFilterConfigurations(materialManagementTileName, transformedResponseData);
            this.storageService.setItem('boxTileFilter', JSON.stringify(transformedResponseData));
            break;
        }
      });
      this.tileFilterSubscriptions$.push(subscription);
    });
    this.getFilterPopupState$ = this.materialManagementService.filterPopupState$.subscribe((event: FilterStateConfig) => {
      this.subscribeToPopupState(event);
    });
  }
  
  
  private setFilterConfigurations(tile: string, filterData: TileFilter[]): FilterConfig {
    return {
      tileName: tile,
      tileFilters: filterData
    };
  }

  private subscribeToPopupState(event: FilterStateConfig): void {
    if (event && event.isDisplayFilter) {
      console.log('isDisplayFilteriR:', this.isDisplayFilter)
      this.showMagnifiedViewPopupData.tile = '';
      this.isDisplayFilter = event.isDisplayFilter;
      console.log('isDisplayFilteripR:', this.isDisplayFilter)

      switch (event.tile) {
        case this.adamLabels.materialManagement_module.dispensedRestockedLabel:
          this.filterConfig = this.dispensedRestockFilterConfig;
          console.log('Filter Config (Dispensed Restocked):', this.filterConfig);

          break;
        case this.adamLabels.materialManagement_module.inventoryTileName:
          this.filterConfig = this.inventoryFilterConfig;
          console.log('Filter Config (Inventory):', this.filterConfig);

          break;
        case this.adamLabels.materialManagement_module.boxesOrderedReplenishedTileName:
          this.filterConfig = this.boxesOrderedReplenishedFilterConfig;
          break;
      }
    } else {
      this.isDisplayFilter = false;
    }
    console.log('isDisplayFilterR:', this.isDisplayFilter)
  }

  public applyFilter(filterData: FilterConfig): void {
    console.log('hi')
    this.isDisplayFilter = false;
    this.materialManagementService.setTileDataFilters(filterData.tileName, filterData.tileFilters);
    this.saveFilterState(filterData);
    this.materialManagementService.setFilterPopupState({ isDisplayFilter: false, tile: '' });
    console.log('isDisplayFilterRS:', this.isDisplayFilter)
  }
  

  private saveFilterState(filterData: FilterConfig): void {
    switch (filterData.tileName) {
      case this.adamLabels.materialManagement_module.dispensedRestockedTileName:
        this.dispensedRestockFilterConfig = filterData;
        break;
      case this.adamLabels.materialManagement_module.inventoryTileName:
        this.inventoryFilterConfig = filterData;
        break;
      case this.adamLabels.materialManagement_module.boxesOrderedReplenishedTileName:
        this.boxesOrderedReplenishedFilterConfig = filterData;
        break;
    }
  }

  public showMagnifiedPopup(event: MagnifiedViewModel): void {
    this.showMagnifiedViewPopupData = event;
  }

  public ngOnDestroy(): void {
    if (this.getFilterPopupState$) {
      this.getFilterPopupState$.unsubscribe();
    }
    if (this.tileFilterSubscriptions$.length > 0) {
      while (this.tileFilterSubscriptions$.length > 0) {
        const subscription = this.tileFilterSubscriptions$.pop();
        if (subscription) {
          subscription.unsubscribe();
        }
      }
    }
  }
  
}
