<div class="box-ordered-replenished">
    <div class="row header">
      <div class="col-md-6">
        <span class="card-heading">Boxes Ordered vs. Replenished</span>
        <span class="pt-2">
          <em class="material-icons pointer" (keydown)="navigateToBoxesOrderedOverview()" (click)="navigateToBoxesOrderedOverview()">info_outline</em>
        </span>
      </div>
      <div class="col-md-3 mt-3">
        <div class="filter-expand float-right">
          <div class="filter-container" (keydown)="displayFilter()" (click)="displayFilter()">
            <p class="filter-text">Filters <em class="material-icons filter-icon">filter_list</em></p>
          </div>
          <div class="display" (keydown)="showMagnifiedView()" (click)="showMagnifiedView()" *ngIf="!showMagnifiedViewPopupData.isMagnifiedView">
            <img class="expand-img" src="./assets/img/maximize_icon.png" alt="Expand image" />
          </div>
          <div class="display" (keydown)="showMagnifiedView()" (click)="showMagnifiedView()" *ngIf="showMagnifiedViewPopupData.isMagnifiedView">
            <img class="expand-img" src="./assets/img/minimize_icon.png" alt="Minimize image" />
          </div>
        </div>
      </div>
    </div>
    <div class="sub-head">
      <span *ngFor="let item of selectedOptions; let i = index;">
        {{item}}<span *ngIf="selectedOptions.length > 1 && i != selectedOptions.length - 1">, </span>
      </span>
    </div>
    <div class="row mr-2 tile-body">
      <div class="col-xl-2 col-lg-2 col-md-3 mb-4 column">
        <ng-container *ngIf="boxesOrderedReplenishedData">
          <div class="boxes-ordered-replenished-tile-kpi-box" *ngFor="let callOut of boxesOrderedReplenishedData.calloutData" [ngClass]="{'tall-callout': callOut.label === adamLabels.materialManagement_module.replenishedRatioCallout}">
            <p class="box-key mt-3">{{callOut.label}}:</p>
            <p class="box-value">{{callOut.firstDetailValue}}</p>
          </div>
        </ng-container>
      </div>
      <div class="col-xl-10 col-lg-8 col-md-6 column chart-container">
        <div class="text-center">
          <div class="legend d-inline-block">
            <span class="ordered-legend d-inline-block"></span>
            <p class="legend-text d-inline-block ms-1">{{adamLabels.materialManagement_module.bar1Label}}</p>
            <span class="replenished-legend d-inline-block ms-2"></span>
            <p class="legend-text d-inline-block ms-1">{{adamLabels.materialManagement_module.bar2Label}}</p>
          </div>
        </div>
        <app-shared-grouped-bar-chart [boxesOrderedReplenishedBarData]="barChartData" [barConfig]="barConfig" [chartMetaData]="chartMetaData"></app-shared-grouped-bar-chart>
      </div>
    </div>
  </div>
  