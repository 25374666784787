<div class="footer">
    <div class="navbar navbar-expand-lg float-left foo1 desktop-mode">
        <a (click)="navigateToHome()"><img src="assets/img/AITA logo.svg" alt="AITA Logo" /></a>
    </div>
    <div class="col-xl-6 col-lg-4 col-md-9 col-sm-8 tl foo foo2 float-left">
        <a class="links" [routerLink]="'/customer-support'" *ngIf="!isOps">{{adamLabels.footer_module.footer1}}</a>
        <a class="links pl-5" [routerLink]="'/privacy-policy'">{{adamLabels.footer_module.footer2}}</a>
        <a class="links pl-5" [routerLink]="'/legal-notice'">{{adamLabels.footer_module.footer3}}</a>
        <a class="links pl-5" [routerLink]="'/pricing-disclosure'">{{adamLabels.footer_module.footer4}}</a>
    </div>
    <div class="navbar navbar-expand-lg float-left foo1 tablet-mode">
        <a (click)="navigateToHome()"><img src="/assets/img/AITA logo.svg" alt="AITA Logo" /></a>
    </div>
    <div class="col-xl-3 col-lg-3 col-md-9 col-sm-8 tc foo3 float-right">
        <span>&copy; {{adamLabels.footer_module.startYear}}{{currentYear}} {{adamLabels.footer_module.jnjTag}} &nbsp; &nbsp; &nbsp; {{adamLabels.footer_module.azure20tag}}</span>
    </div>
</div>
