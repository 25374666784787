<p-dialog [(visible)]="showMagnifiedViewPopupData['isMagnifiedView']" [style]="{width: '55vw'}"
    [baseZIndex]="10000" showHeader="false" [modal]="true" [responsive]="true" [closeOnEscape]="false"
    [closable]="false" styleClass="customDialog">

    <div class="popup-container">

        <app-or-scanning-tile [showMagnifiedViewPopupData]="showMagnifiedViewPopupData"
            (showMagnifiedPopup)="showMagnifiedPopup($event)"
            [hidden]="showMagnifiedViewPopupData['tile'] !== adamLabels.clinical_module.orScanningLabel "></app-or-scanning-tile>

        <app-preference-card-accuracy-tile [showMagnifiedViewPopupData]="showMagnifiedViewPopupData"
            (showMagnifiedPopup)="showMagnifiedPopup($event)"
            [hidden]="showMagnifiedViewPopupData['tile'] !== adamLabels.clinical_module.preferenceCardAccuracyLabel ">
        </app-preference-card-accuracy-tile>

        <app-products-usage-returns-tile [showMagnifiedViewPopupData]="showMagnifiedViewPopupData"
            (showMagnifiedPopup)="showMagnifiedPopup($event)"
            [hidden]="showMagnifiedViewPopupData['tile'] !== adamLabels.clinical_module.productUsageReturnsLabel "></app-products-usage-returns-tile>

        <app-financial-overview-tile [showMagnifiedViewPopupData]="showMagnifiedViewPopupData"
            (showMagnifiedPopup)="showMagnifiedPopup($event)"
            [hidden]="showMagnifiedViewPopupData['tile'] !== adamLabels.clinical_module.financialOverviewLabel "></app-financial-overview-tile>
    </div> 
</p-dialog>