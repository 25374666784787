import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription, timer } from 'rxjs';
import { PieChartDataModel } from '@app/shared/models/pie-chart.model';
import { MaterialsManagementService } from '../../materials-management/materials-management.service';
import { MagnifiedViewModel, TodayProgress } from '../../models/materials-management-dashboard.model';
import { AdamConf } from '@app/app.config';
import { LoggerService } from '@app/core/logger.service';
import { AuthService } from '@app/auth/auth.service';
import { PieChartComponent } from 'src/app/shared/components/pie-chart/pie-chart.component';


@Component({
  selector: 'app-core-dashboard-materials-management-today-progress-tracker-tile',
  standalone: true,
  imports: [CommonModule,PieChartComponent],
  templateUrl: './today-progress-tracker-tile.component.html',
  styleUrls: ['./today-progress-tracker-tile.component.scss']
})
export class TodayProgressTrackerTileComponent implements OnInit, OnDestroy {

  public adamLabels = AdamConf;
  public pickingTileData$: Subscription;
  public restockingTileData$: Subscription;
  public replenishmentTileData$: Subscription;
  public orderingTileData$: Subscription;
  public ticker$: Subscription;
  public summary: string;
  public viewPickingPieChartData: PieChartDataModel[];
  public viewRestockPieChartData: PieChartDataModel[];
  public viewReplenishPieChartData: PieChartDataModel[];
  public viewOrderPieChartData: PieChartDataModel[];
  public pieValue: number;
  public pieValueRemaining: number;
  @Input() showMagnifiedViewPopupData: MagnifiedViewModel;
  @Output() showMagnifiedPopup = new EventEmitter<MagnifiedViewModel>();
  public viewTransfersPieChartData: PieChartDataModel[];
  public tranfersTileData$: Subscription;
  viewTransferPieChartData: PieChartDataModel[];

  constructor(
    private readonly baseService: MaterialsManagementService,
    private readonly logger: LoggerService,
    private readonly authService: AuthService
  ) { }

  public ngOnInit(): void {
    this.getTileData();
    this.setAutoRefresh();
  }

  private setAutoRefresh(): void {
    const currentUser = this.authService.getCurrentUser();
    if (currentUser && currentUser.isAutoRefreshEnabled === true && this.showMagnifiedViewPopupData.isMagnifiedView !== true) {
      const delayToStartTimer = currentUser.autoRefreshTimeInterval ?? 0; // Default to 0 if undefined
      const timerInterval = delayToStartTimer;
      const source = timer(delayToStartTimer, timerInterval);
      this.ticker$ = source.subscribe(() => {
        this.logger.log('Refreshing Today\'s progress tracker tile data');
        this.getTileData();
      });
    }
  }
  
  

  public getTileData(): void {
    this.viewPickingPieChartData = [];
    this.viewRestockPieChartData = [];
    this.viewOrderPieChartData = [];
    this.viewReplenishPieChartData = [];
    this.viewTransfersPieChartData = [];
    this.resetProgressTrackerSubscriptions();
    this.pickingTileData$ = this.baseService.pickingData$.subscribe((tileData) => {
      this.viewPickingPieChartData = this.setPieValue(tileData);
    });
    this.restockingTileData$ = this.baseService.restockingData$.subscribe((tileData) => {
      this.viewRestockPieChartData = this.setPieValue(tileData);
    });
    this.orderingTileData$ = this.baseService.orderingData$.subscribe((tileData) => {
      this.viewOrderPieChartData = this.setPieValue(tileData);
    });
    this.replenishmentTileData$ = this.baseService.replenishmentData$.subscribe((tileData) => {
      this.viewReplenishPieChartData = this.setPieValue(tileData);
    });
    this.tranfersTileData$ = this.baseService.transfersData$.subscribe((tileData) => {
      this.viewTransfersPieChartData = this.setPieValue(tileData);
    });
  }

  private setPieValue(tileData: TodayProgress): PieChartDataModel[] {
    if (tileData) {
      this.pieValue = Number(tileData.pieValue);
      if (this.pieValue >= 0) {
        return this.getPieChartViewModelData(this.pieValue);
      }
    }
    return []; // Return an empty array if tileData is undefined or pieValue is less than 0
  }
  

  private getPieChartViewModelData(item: number): PieChartDataModel[] {
    let pieChartData: PieChartDataModel[];
    let remaining = 0;
    const pieValue = item;
    const totalPercentageValue = 100;
    if (item >= totalPercentageValue) {
      item = totalPercentageValue;
    }
    if (item < totalPercentageValue) {
      remaining = totalPercentageValue - item;
    }

    pieChartData = [
      { value: item, color: '#12224D', label: pieValue + '', tooltipData: [] },
      { value: remaining, color: '#F56600', label: remaining + '', tooltipData: [] }];
    return pieChartData;
  }

  private resetProgressTrackerSubscriptions(): void {
    if (this.pickingTileData$) {
      this.pickingTileData$.unsubscribe();
    } else if (this.restockingTileData$) {
      this.restockingTileData$.unsubscribe();
    } else if (this.replenishmentTileData$) {
      this.replenishmentTileData$.unsubscribe();
    } else if (this.orderingTileData$) {
      this.orderingTileData$.unsubscribe();
    }
  }

  public ngOnDestroy(): void {
    this.resetProgressTrackerSubscriptions();
    if (this.ticker$) {
      this.ticker$.unsubscribe();
    }
  }

  public showMagnifiedView(): void {
    this.showMagnifiedViewPopupData.isMagnifiedView = (this.showMagnifiedViewPopupData.isMagnifiedView === true) ? false : true;
    this.showMagnifiedViewPopupData.tile = (this.showMagnifiedViewPopupData.isMagnifiedView === true) ?
      this.adamLabels.materialManagement_module.todayProgressTileName : '';
    this.showMagnifiedPopup.emit(this.showMagnifiedViewPopupData);
  }

}
