import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdamConf } from '@app/app.config';
import { AuthService } from '@app/auth/auth.service';
import { LoggerService } from '@app/core/logger.service';
import * as _ from 'lodash';
import { Subscription, timer } from 'rxjs';
import { ClinicalService } from '../clinical.service';
import{AverageDailyUsagePipe} from 'src/app/shared/pipes/average-daily-usage.pipe'
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-preference-card-overview-tile',
  standalone: true,
  imports: [AverageDailyUsagePipe,CommonModule ],
  templateUrl: './preference-card-overview-tile.component.html',
  styleUrl: './preference-card-overview-tile.component.scss'
})
export class PreferenceCardOverviewTileComponent implements OnInit, OnDestroy {
  public preferenceCardData: any;
  public adamLabels = AdamConf;
  private kpiDataSubscription$: Subscription;
  private ticker$: Subscription;

  constructor(
    private readonly clinicalService: ClinicalService,
    private readonly router: Router,
    private readonly logger: LoggerService,
    private readonly authService: AuthService) {
  }

  public ngOnInit(): void {
    this.loadPreferenceCardTileData();
    this.setAutoRefresh();
  }

  private loadPreferenceCardTileData(): void {
    this.resetKpiDataSubscription();
    this.kpiDataSubscription$ = this.clinicalService.getClinicalTowerKpis('Preference Card Overview').subscribe((resp) => {
      if (resp.responseCode === '2001') {
        resp.responseData.keyValueItems.forEach(element => {
          const routerParams = element.link.substring(element.link.lastIndexOf('/') + 1);
          element.routerParams = routerParams;
        });
        const tileData = resp.responseData.keyValueItems;
        this.preferenceCardData = _.orderBy(tileData, ['order'], ['asc']);
      } else {
        this.preferenceCardData = null;
      }
    });
  }

  private setAutoRefresh(): void {
    const currentUser = this.authService.getCurrentUser();
    if (currentUser.isAutoRefreshEnabled === true) {
      const delayToStartTimer = currentUser.autoRefreshTimeInterval;
      const timerInterval = delayToStartTimer;
      const source = timer(delayToStartTimer, timerInterval);
      this.ticker$ = source.subscribe((val) => {
        this.logger.log('Refreshing Preference Card KPI tile data');
        this.loadPreferenceCardTileData();
      });
    }
  }

  public navigateToPreferenceCardDetails(params: string): void {
    this.router.navigate(['/insights/preference-card/overview', params]);
  }

  public ngOnDestroy(): void {
    this.resetKpiDataSubscription();
    if (this.ticker$) {
      this.ticker$.unsubscribe();
    }
  }

  private resetKpiDataSubscription(): void {
    if (this.kpiDataSubscription$) {
      this.kpiDataSubscription$.unsubscribe();
    }
  }

}
