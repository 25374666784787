<div class="todays-progress-tracker-tile">
    <div class="header pt-2 pb-4">
      <span class="card-heading">Today's Progress Tracker</span>
      <div class="expand-container" (click)="showMagnifiedView()" (keypress)="showMagnifiedView()" *ngIf="!showMagnifiedViewPopupData.isMagnifiedView">
        <img class="expand-icon" src="./assets/img/maximize_icon.png" alt="Expand image" />
      </div>
      <div class="expand-container" (click)="showMagnifiedView()" (keypress)="showMagnifiedView()" *ngIf="showMagnifiedViewPopupData.isMagnifiedView">
        <img class="expand-icon" src="./assets/img/minimize_icon.png" alt="Expand image" />
      </div>
    </div>
  
    <div class="row today-pie-chart mt-5">
      <div class="col-xl-12 col-lg-12 col-md-12 column chart-container">
        <div class="inventory-pie-one">
          <div class="pie-container">
            <app-pie-chart-view [title]="'Picking'" [dataFormat]="'percentage'" [textColor]="'#FFF'" [pieData]="viewPickingPieChartData"></app-pie-chart-view>
          </div>
        </div>
        <div class="inventory-pie-one">
          <div class="pie-container">
            <app-pie-chart-view [title]="'Restocking'" [dataFormat]="'percentage'" [textColor]="'#FFF'" [pieData]="viewRestockPieChartData"></app-pie-chart-view>
          </div>
        </div>
        <div class="inventory-pie-one">
          <div class="pie-container">
            <app-pie-chart-view [title]="'Replenishment'" [dataFormat]="'percentage'" [textColor]="'#FFF'" [pieData]="viewReplenishPieChartData"></app-pie-chart-view>
          </div>
        </div>
        <div class="inventory-pie-one">
          <div class="pie-container">
            <app-pie-chart-view [title]="'Ordering'" [dataFormat]="'percentage'" [textColor]="'#FFF'" [pieData]="viewOrderPieChartData"></app-pie-chart-view>
          </div>
        </div>
        <div class="inventory-pie-one">
          <div class="pie-container">
            <app-pie-chart-view [title]="'Transfers'" [dataFormat]="'percentage'" [textColor]="'#FFF'" [pieData]="viewTransfersPieChartData"></app-pie-chart-view>
          </div>
        </div>
      </div>
      <div class="col-xl-12 col-lg-12 col-md-12 graph-data">
        <div class="text-center">
          <div class="legend d-inline-block">
            <span class="completed-legend d-inline-block"></span>
            <p class="legend-text d-inline-block ms-1">Completed</p>
            <span class="wip-legend d-inline-block ms-2"></span>
            <p class="legend-text d-inline-block ms-1">Remaining</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  