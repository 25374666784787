import { Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { DomainGuard } from './auth/domain.guard';
import { HomeComponent } from './core/home/home.component';
import { CustomerSupportComponent } from './core/footer/customer-support/customer-support.component';
import { PrivacyPolicyComponent } from './core/footer/privacy-policy/privacy-policy.component';
import { LegalNoticeComponent } from './core/footer/legal-notice/legal-notice.component';
import { PricingDisclosureComponent } from './core/footer/pricing-disclosure/pricing-disclosure.component';
import { LoginComponent } from './core/login/login.component';
import { DashboardComponent } from './core/dashboard/dashboard.component';
import { MaterialsManagementContainerComponent } from './core/dashboard/materials-management/materials-management-container/materials-management-container.component';
import { ClinicalContainerComponent } from './core/dashboard/clinical/clinical-container/clinical-container.component';
import { ForgotPasswordComponent } from './core/forgot-password/forgot-password.component';

export const routes: Routes = [
    {
        path: '', canActivate: [AuthGuard, DomainGuard], canActivateChild: [AuthGuard],
        children: [
          { path: '', redirectTo: 'external', pathMatch: 'full' },
          { path: 'home', component: HomeComponent, data: { module: 'home' } },
          {
            path: '', component: DashboardComponent, children: [
              {
                path: 'dashboard', component: DashboardComponent, data: {}
              },
              {
                path: 'dashboard/materials-management', component: MaterialsManagementContainerComponent, data: {}
              },
              {
                path: 'dashboard/clinical', component: ClinicalContainerComponent, data: {}
              }
            ]
          },
          { path: 'customer-support', component: CustomerSupportComponent },
          { path: 'privacy-policy', component: PrivacyPolicyComponent },
          { path: 'legal-notice', component: LegalNoticeComponent },
          { path: 'pricing-disclosure', component: PricingDisclosureComponent },
          { path: 'login', component: LoginComponent, data: { module: 'login' } },
          { path: 'forgot-password', component: ForgotPasswordComponent, data: { module: 'forgot-password' } },
          { path: 'dashboard', component: DashboardComponent, data: { module: 'dashboard' } },
          { path: 'control-tower', loadChildren: () => import('./control-tower/control-tower.routes').then(m => m.CONTROL_TOWER_ROUTES) },
          { path: 'inventories', loadChildren: () => import('./inventories/inventories.routes').then(m => m.INVENTORIES_ROUTES) },
          { path: 'pick-request', loadChildren: () => import('./pick-request/pick-request.routes').then(m => m.PICKREQUEST_ROUTES) },
          { path: 'insights', loadChildren: () => import('./insights/insights.routes').then(m => m.INSIGHTS_ROUTES) },
        ]
    }
    
];

// export function setupScrollRestoration() {
//   const viewportScroller = inject(ViewportScroller);
//   return () => {
//     window.addEventListener('popstate', () => {
//       viewportScroller.scrollToPosition([0, 0]);
//     });
//   };
// }

// setupScrollRestoration();
