
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

const SECRET_KEY = 'unuweiurwyerwus';

@Injectable({
    providedIn: "root"
})

export class StorageService {
  private isLocalStorageAvailable: boolean;
  private inMemoryStorage: { [key: string]: string } = {};

  constructor() {
    this.isLocalStorageAvailable = this.checkLocalStorageAvailability();
  }

  private checkLocalStorageAvailability(): boolean {
    try {
      const testKey = '__test__';
      localStorage.setItem(testKey, testKey);
      localStorage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }

  setItem(key: string, value: string): void {
    if (this.isLocalStorageAvailable) {
      localStorage.setItem(key, value);
    } else {
      this.inMemoryStorage[key] = value;
    }
  }

  getItem(key: string): string | null {
    if (this.isLocalStorageAvailable) {
      return localStorage.getItem(key);
    } else {
      return this.inMemoryStorage[key] || null;
    }
  }

  removeItem(key: string): void {
    if (this.isLocalStorageAvailable) {
      localStorage.removeItem(key);
    } else {
      delete this.inMemoryStorage[key];
    }
  }

    

    private encrypt = function encrypt(data: any) {
        data = CryptoJS.AES.encrypt(data, SECRET_KEY);
        data = data.toString();
        return data;
    }
    private decrypt = function decrypt(data: any) {
        try {
            data = CryptoJS.AES.decrypt(data, SECRET_KEY);
            if (data.toString()) {
                return data.toString(CryptoJS.enc.Utf8);
            }
        }
        catch (error) {
            data = null;
        }
        return data;
    }

    // // Localstorage methods
    // setItem(key: string, value: any) {
    //     localStorage.setItem(key, this.encrypt(value));

    // }
    // getItem(key: string) {
    //     return this.decrypt(localStorage.getItem(key));
    // }
    // removeItem(key: string) {
    //     localStorage.removeItem(key);
    // }

    //session methods
    setSessionItem(key: string, value: any) {
      if (typeof window !== 'undefined' &&  typeof sessionStorage !== 'undefined') {
        value = value.toString();
        sessionStorage.setItem(key, this.encrypt(value));
      }
    }
    getSessionItem(key: string) {
      if (typeof window !== 'undefined' && typeof sessionStorage !== 'undefined') {
        return this.decrypt(sessionStorage.getItem(key));
      }
    }
    removeSessionItem(key: string) {
      if (typeof window !== 'undefined' && typeof sessionStorage !== 'undefined') {
        sessionStorage.removeItem(key);
      }
    }
}