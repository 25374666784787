<div class="dispensed-restocked">
    <div class="row header">
      <div class="col-md-5">
        <span class="card-heading">Dispensed & Restocked</span>
        <span class="pt-2">
          <em class="material-icons pointer" (keydown)="navigateToPreferenceCardOverview()" (click)="navigateToPreferenceCardOverview()">info_outline</em>
        </span>
      </div>
      <div class="col-md-3 margin-left mt-3">
        <div class="filter-expand float-end">
          <div class="filter-container" (keydown)="displayFilter()" (click)="displayFilter()">
            <p class="filter-text">Filters <em class="material-icons filter-icon">filter_list</em></p>
          </div>
          <div class="display" (keydown)="showMagnifiedView()" (click)="showMagnifiedView()" *ngIf="!showMagnifiedViewPopupData.isMagnifiedView">
            <img class="expand-img" src="./assets/img/maximize_icon.png" alt="Expand image" />
          </div>
          <div class="display" (keydown)="showMagnifiedView()" (click)="showMagnifiedView()" *ngIf="showMagnifiedViewPopupData.isMagnifiedView">
            <img class="expand-img" src="./assets/img/minimize_icon.png" alt="Minimize image" />
          </div>
        </div>
      </div>
    </div>
    <div class="sub-head">
      <span *ngFor="let item of selectedOptions; let i = index;">
        {{item}}<span *ngIf="selectedOptions.length > 1 && i != selectedOptions.length - 1">, </span>
      </span>
    </div>
    <div class="row me-2 tile-body">
      <div class="col-xl-2 col-lg-2 col-md-3 mb-3 column">
        <div class="left-tile">
          <div class="tile" *ngFor="let callOut of lineChartData?.calloutData" [ngClass]="{'tall-callout': callOut.label === adamLabels.materialManagement_module.restockedRatioCallout}">
            <p class="text">{{callOut.label}}:</p>
            <p class="value">{{callOut.firstDetailValue}}</p>
          </div>
        </div>
      </div>
      <div class="col-xl-10 col-lg-8 col-md-6 column financial-graph-data">
        <div class="legend pt-2">
          <span class="budget-dollars-value">
            <em class="material-icons legend-icon blue-icon">commit</em>
            Dispensed
          </span>
          <span class="budget-dollars-value">
            <em class="material-icons legend-icon grey-icon">commit</em>
            Restocked
          </span>
        </div>
        <app-common-line-graph [lineConfig]="lineConfig" [lineChartData]="lineChartData" [chartMetaData]="chartMetaData"></app-common-line-graph>
      </div>
    </div>
  </div>
  