import { CommonModule } from '@angular/common';
import { Component, ViewChild, ElementRef, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { AdamConf } from '@app/app.config';
import { AverageDailyUsagePipe } from '@app/shared/pipes/average-daily-usage.pipe';
import { PDialogComponent } from '@app/util/p-dialog/p-dialog.component';
import * as d3 from 'd3';
import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@Component({
  selector: 'app-stacked-bar-chart',
  standalone: true,
  imports: [],
  templateUrl: './stacked-bar-chart.component.html',
  styleUrls: ['./stacked-bar-chart.component.scss'],
})
export class StackedBarChartComponent implements OnChanges {
  @Input() chartData: { category: string; line: number; bar1ToolTipInfo: any[]; bar2ToolTipInfo: any[]; lineToolTipInfo: any[]; total?: number; [key: string]: any }[];
  @Input() chartMetaData: any;
  @ViewChild('chartContainer', { static: false })
  private chartContainer?: ElementRef;
  public adamLabels: any;

  constructor() {
    // constructor
  }

  public ngOnChanges(): void {
    this.adamLabels = AdamConf;
    if (this.chartData && this.chartData.length > 0) {
      this.initChart();
    }
  }

  private initChart(): void {
    const self = this;
    d3.select(this.chartContainer.nativeElement).selectAll('svg').remove();

    let width = this.chartContainer.nativeElement.offsetWidth - this.chartMetaData.margin.left - this.chartMetaData.margin.right;
    let height = this.chartContainer.nativeElement.offsetHeight - this.chartMetaData.margin.top - this.chartMetaData.margin.bottom;

    const rangeAdjVal = .27;
    const rangeAdjval1 = .5
    const x = d3.scaleBand()
      .rangeRound([0, width])
      .paddingInner(rangeAdjVal)
      .paddingOuter(rangeAdjval1);
    const y = d3.scaleLinear()
      .rangeRound([height, 0]);
    const y1 = d3.scaleLinear().range([height, 0]);

    let colorRange = [];
    if (this.chartMetaData.chartLabel === this.adamLabels.clinical_module.productUsageReturnsLabel) {
      colorRange = ['#12224D', '#B1C9E8', '#D0CFCD'];
    } else {
      colorRange = ['#12224D', '#888B8D', '#D0CFCD'];
    }
    const color = d3.scaleOrdinal()
      .range(colorRange);

    const svg = d3.select(self.chartContainer.nativeElement).append('svg')
      .attr('width', width + self.chartMetaData.margin.left + self.chartMetaData.margin.right)
      .attr('height', height + self.chartMetaData.margin.top + self.chartMetaData.margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + self.chartMetaData.margin.left + ',' + self.chartMetaData.margin.top + ')');



    const xAxis = d3.axisBottom(x);
    const yAxis = d3.axisLeft(y)
  .tickFormat((d) => {
    const value = Number(d); // Convert d to a number
    if (value >= 1000) {
      return d3.format('.1f')(value / 1000) + 'k';
    } else {
      return d3.format('.1f')(value);
    }
  });
  const yAxisRight = d3.axisRight(y1)
  .tickFormat((d) => {
    return d + '%';
  });


    color.domain(Object.keys(self.chartData[0]).filter(function (key) { return key !== 'category' && key !== 'line' && key !== 'bar1ToolTipInfo' && key !== 'bar2ToolTipInfo' && key !== 'lineToolTipInfo'; }));
    self.chartData.forEach(function (d, index) {
      var y0 = 0;
      d['values'] = color.domain().map(function (name) {
        let obj = {
          name: name, y0: y0, y1: y0 + Number(d[name]), item: d
        };
        y0 = y0 + Number(d[name]);
        return obj;
      });
      d['total'] = d['values'][d['values'].length - 1].y1;
    });
    x.domain(self.chartData.map(function (d) { return d.category; }));
    y.domain([0, d3.max(self.chartData, function (d) { return d.total as number; })]);
    const y1DurationLowerLimit = 0;
    const y1DurationUpperLimit = 100;
    y1.domain([y1DurationLowerLimit, y1DurationUpperLimit]);

    svg.append('g')
      .attr('class', 'x axis xAxis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis).select('path')
      .style('stroke', '#d0cfcd')
      .style('fill', 'none')
      .style('stroke-width', 1)
      .style('shape-rendering', 'crispEdges').style('font-size', '8px');
    svg.append('g')
      .attr('class', 'y axis yAxis')
      .call(yAxis).select('path')
      .style('stroke', '#d0cfcd')
      .style('fill', 'none')
      .style('stroke-width', 1)
      .style('shape-rendering', 'crispEdges')
      .style('stroke-dasharray', '2')
      .append('text')
      .attr('transform', 'rotate(-90)');
    svg.append('g')
      .attr('class', 'y axis yRight')
      .attr('transform', 'translate(' + width + ' ,0)')
      .call(yAxisRight).select('path')
      .style('stroke', '#d0cfcd')
      .style('fill', 'none')
      .style('stroke-width', 1)
      .style('shape-rendering', 'crispEdges')
      .style('stroke-dasharray', '2')
      .append('text')
      .attr('transform', 'rotate(-90)');

    // Draw Grid lines - X axis
    svg.selectAll('g.xAxis g.tick')
      .append('line')
      .attr('class', 'gridline x')
      .attr('x1', 0)
      .attr('y1', -height)
      .attr('x2', 0)
      .attr('y2', 0)
      .attr('stroke-width', 1)
      .style('opacity', '1')
      .style('stroke', '#d0cfcd')
      .style('stroke-dasharray', '2');

    // Draw Grid lines - Y axis
    svg.selectAll('g.yAxis g.tick')
      .append('line')
      .attr('class', 'gridline y')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', width)
      .attr('y2', 0)
      .attr('stroke-width', 1)
      .style('opacity', '1')
      .style('stroke', '#d0cfcd')
      .style('stroke-dasharray', '2');

    svg.selectAll('.xAxis .tick text')
      .style('text-anchor', 'end')
      .style('font-size', '10px')
      .style('font-family', 'Noto Sans Regular')
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr('transform', 'translate(-1.5,1) rotate(-45)');

    svg.selectAll('g.yRight .tick text')
      .style('font-size', '8px')
      .style('font-family', 'Noto Sans Regular');

    svg.selectAll('g.yAxis .tick text')
      .style('font-size', '8px')
      .style('font-family', 'Noto Sans Regular');

    const bar = svg.selectAll('.category')
      .data(self.chartData)
      .enter().append('g')
      .attr('class', 'g')
      .attr('transform', (d) => {
        const valAdjIndex = 2;
         return 'translate(' + (x(d.category) - (x.bandwidth() / valAdjIndex)) + ',0)'; 
        });


    const bar_enter = bar.selectAll('rect')
      .data((d) => { return d['values']; })
      .enter();

    // draw stack bar
    this.drawStackBar(bar_enter, x, y, color);

    //add stack bar text
    this.addStackBarText(bar_enter, x, y);

    const transformAdj = 30;
    svg.append('g')
      .attr('class', 'legendLinear')
      .attr('transform', 'translate(0,' + (height + transformAdj) + ')');

    // Define the line
    const valueline = d3.line<{ category: string; line: number }>()
      .x(function (d) { return x(d.category); })
      .y(function (d) { return y1(d.line); }).curve(d3.curveMonotoneX);

    // Add the valueline path.
    // svg.append('path')		// Add the valueline path.
    //   .attr('class', 'line')
    //   .attr('d', valueline(self.chartData.map(d => [x(d.category), y1(d.line)]))).style('stroke', '#fe5000')
    //   .style('stroke-width', '3px').style('fill', 'none');

    svg.append('path')		// Add the valueline path.
      .attr('class', 'line')
      .attr('d', valueline(self.chartData.map(d => ({ category: d.category, line: d.line })))).style('stroke', '#fe5000')
      .style('stroke-width', '3px').style('fill', 'none');

    // Add the valueline path.
    this.setSVGCircle(svg, self, x, y1)
  }

  private drawStackBar(bar_enter: any, x: any, y: any, color: any): void {
    const widthAdjIndex = 2;
    const widthAdjVal = .25;
    bar_enter.append('rect')
      .attr('width', (x.bandwidth() / widthAdjIndex) + ((x.bandwidth() / widthAdjIndex) * widthAdjVal))
      .attr('y', function (d) { return y(d.y1); })
      .attr('height', function (d) { return y(d.y0) - y(d.y1); })
      .style('fill', function (d) { return color(d.name); })
      .on('mouseover', function (d) {
        const duration = 200;
        const transitionOpacity = .9;
        const div = d3.select('body').append('div')
          .attr('class', 'tooltip-chart-line')
          .style('opacity', 0)
          .style('white-space', 'normal'); // Ensure text wraps within the div
        div.transition()
          .duration(duration)
          .style('opacity', transitionOpacity);
        let text = '';
        if (d.lineToolTipInfo.length > 0) {
          text = text + '<table class="graph-table">'; // No fixed width here
          d.lineToolTipInfo.forEach(element => {
            text = text + '<tr><td class="title">' + element.label + ':</td><td>' + element.value + '</td></tr>';
          });
          text = text + '</table>';
          div.html(text);
        }
        const topAdjVal = 28;
        div.style('top', ((event as MouseEvent).pageY - topAdjVal) + 'px')
        div.style('z-index', '10001');
        div.style('visibility', 'visible');
        div.style('left', function () {
          const box = div.node(); // Get the tooltip div element
          if (box) {
            const style = getComputedStyle(box);
            const toolwidth = parseInt(style.width);
            let left = (event as MouseEvent).pageX;
            const upperLimit = 200;
            const leftAdj = 10;
            if (window.innerWidth - ((event as MouseEvent).pageX + toolwidth) < upperLimit) {
              left = (event as MouseEvent).pageX - toolwidth - leftAdj;
            }
            return (left + leftAdj) + 'px';
          }
          return '0px'; // Fallback in case box is not found
        });
      })
      .on('mouseout', function () {
        let tooltip = document.getElementsByClassName('tooltip-chart-line');
        while (tooltip[0]) {
          tooltip[0].parentNode.removeChild(tooltip[0]);
        }
        let div = d3.select('tooltip-chart-line')
        return div.style('visibility', 'hidden');
      });
      
  }

  private addStackBarText(bar_enter: any, x: any, y: any): void {
    const xValAdj = 3;
    bar_enter.append('text')
      .text(function (d) {
        let num = (d.y1 - d.y0);
        if(num === 0){
          return null;
        }
        const upperLimit = 1000;
        if (num < upperLimit) {
          return num;
        } else {
          let si = [
            { v: 1E3, s: "K" },
            { v: 1E6, s: "M" },
            { v: 1E9, s: "B" },
            { v: 1E12, s: "T" },
          ];
          let index;
          for (index = si.length - 1; index > 0; index--) {
            if (num >= si[index].v) {
              break;
            }
          }
          return (num / si[index].v).toFixed(0) + si[index].s;
        }
      })
      .attr('y', function (d) { 
        const valAdjIndex = 2;
        return y(d.y1) + (y(d.y0) - y(d.y1)) / valAdjIndex; })
      .attr('x', x.bandwidth() / xValAdj)
      .attr("text-anchor", "middle")
      .style('fill', '#ffffff')
      .style('font-size', '8px');
  }

  private setSVGCircle(svg: any, self: any, x: any, y1: any): void {
    svg
        .selectAll('circle')
        .data(self.chartData)
        .enter()
        .append('circle')
        .attr('r', (d) => {
            const lowerLimit = 0;
            const upperLimit = 4;
            return d.line === null ? lowerLimit : upperLimit;
        })
        .attr('cx', function (d) {
            return x(d.category);
        })
        .attr('cy', function (d) {
            return y1(d.line);
        })
        .attr('fill', '#FFF')
        .attr('stroke', '#fe5000')
        .on('mouseover', function (event, d) {
            const transactionDuration = 200;
            const transactionOpacity = 0.9;
            const div = d3.select('body').append('div')
                .attr('class', 'tooltip-chart-line')
                .style('opacity', 0)
                
            div.transition()
                .duration(transactionDuration)
                .style('opacity', transactionOpacity);
            let text = '';
            text = text + '<table class="graph-table" style="width: 140px;">';
            if (d.lineToolTipInfo && d.lineToolTipInfo.length > 0) {
                d.lineToolTipInfo.forEach(element => {
                    text = text + '<tr><td class="title">' + element.label + ':  </td><td class="data-value">' + element.value + '</td></tr>';
                });
            } else {
                text = text + '<tr><td class="title">value:  </td><td class="data-value">' + d.line + '</td></tr>';
            }
            text = text + '</table>';
            const topAdj = 28;
            div.html(text);
            div.style('top', ((event as MouseEvent).pageY - topAdj) + 'px');
            div.style('z-index', '10001');
            div.style('visibility', 'visible');
            div.style('left', () => {
              
              const box = div.node();
                console.log('Tooltip div element:', box);
                let style = getComputedStyle(box);
                let toolwidth = parseInt(style.width);
                let left = (event as MouseEvent).pageX;
                const upperLimit = 200;
                if (window.innerWidth - ((event as MouseEvent).pageX + toolwidth) < upperLimit) {
                    const leftAdj = 30;
                    left = (event as MouseEvent).pageX - toolwidth - leftAdj;
                }
                const leftValAdj = 10;
                return (left + leftValAdj) + 'px';
            });
        })
        .on('mouseout', function () {
            let tooltips = document.getElementsByClassName('tooltip-chart-line');
            while (tooltips[0]) {
                tooltips[0].parentNode.removeChild(tooltips[0]);
            }
            let div = d3.select('.tooltip-chart-line');
            return div.style('visibility', 'hidden');
        });
}



}
