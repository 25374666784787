export const AdamopsConf = {
    // App settings
    ROWS_PER_PAGE: '10',
    SEARCH_HISTORY_COUNT: 20,
    LOADER_EXCLUSIONS: ['products/search'],
    SUMMARY: '',
    COVERAGE: '',
    KISOK: '',
    SHELF: '',
    HUB: '',
    SUMMARY_KPI: '',
    COVERAGE_KPI: '',
    KISOK_KPI: '',
    SHELF_KPI: '',
    HUB_KPI: '',
    CONFIG_VALIDATORS: {
        VALIDATION_SUCCESS: 'VALIDATION_SUCCESS',
        RUN_COMPLETED: 'RUN_COMPLETED',
        UPLOAD_NEW_FILE_CODES: ['VALIDATION_ERROR', 'VALIDATION_SUCCESS', 'RUN_FAILED', 'RUN_COMPLETED'],
        RUN_NEW_MODEL_SINGLE_RECORD_CODES: ['RUN_COMPLETED', 'RUN_FAILED'],
        RUN_NEW_MODEL_MULTIPLE_RECORD_CODES: ['VALIDATION_SUCCESS', 'RUN_COMPLETED', 'RUN_FAILED'],
        FILE_VALIDATION_FAILED_CODES: ['VALIDATION_ERROR'],
        FILE_VALIDATION_IN_PROGRESS: ['VALIDATION_STARTED'],
        RUN_IN_PROGRESS_CONFIGURATOR_PAGE: ['RUN_INITIATED'],
        RUN_IN_PROGRESS_CODES: ['RUN_INITIATED', 'RUN_IN_PROGRESS'],
        RUN_FAILED: 'RUN_FAILED',
        POLLING_INTERVAL: 10000
    },
    minTimeLeftToRefreshToken: 900000, // 15 mins in milli secs
    // Labels
    dashboard: {
        hospital_heading: 'Novant Health Medical Park 00007008 - preference card data has been received from the hospital',
        review: 'Review Data',
        add_new_hospital: 'Add new hospital',
    },
    new_hospital: {
        create_hospital: 'Add hospital',
        cancel: 'Cancel',
        search_by_hosptial: 'Search hospital name or unique ID number',
    },
    action:
    {
        assessment_tool: 'Assessment tool'
    },
    units: {
        tblHead1: 'NAME',
        tblHead2: 'LOCATION',
        tblHead3: 'UNIT TYPE',
        tblHead4: 'SERIAL NUMBER',
        tblHead5: 'ACTIONS/STATUS',
        add_unit: 'Add Unit',
        confirm_text: 'Are you sure you want to delete this unit?',
        go_back: 'No, go back',
        yes: 'Yes',
        no_record_txt1: 'There are no added units.',
        no_record_txt2: 'As of ',
        upload_text: 'Upload private key files',
        download_text: 'Download provision file',
        click_to_provision: 'Click To Provision',
        uploading: 'Uploading...',
        downloading: 'Downloading...',
        failed: 'Failed, please try again',
        registered: 'Registered',
        provisioned: 'Provisioned',
        invalid_file: 'Invalid file format',
        upload_error: 'Please select one .csr and one .key file',
        error_field: 'Field: ',
        error_desc: 'Reason: '
    },
    configuration: {
        configName: 'Name',
        configKey: 'Config Key',
        value: 'Value',
        edit: 'Edit',
    },
    configuration_tabs : [
        {
          "label": "configurations/picking",
          "link": "PICKING",
          "value": "picking",
          "id": 1,
          "active": true
        },
        {
          "label": "configurations/inventory",
          "link": "INVENTORY",
          "value": "inventory",
          "id": 2,
          "active": false
        },
        {
          "label": "configurations/user",
          "link": "USER",
          "value": "user",
          "id": 3,
          "active": false
        },
        {
          "label": "configurations/device-notification",
          "link": "DEVICE AND NOTIFICATION",
          "value": "deviceandnotification",
          "id": 4,
          "active": false
        },
        {
          "label": "configurations/hospital-common-configuration",
          "link": "HOSPITAL COMMON CONFIGURATION",
          "value": "hospitalcommonconfiguration",
          "id": 5,
          "active": false
        }
    ],
    tabs: {
        actions: 'ACTIONS',
        units: 'UNITS',
        back: 'Back',
        configuration: 'Configuration',
    },
    config_data: {
        back: 'Back',
        cancel: 'Cancel',
        run_model: 'Run model',
        run_new_model: 'Run new model',
        config_tool: 'Configurator tool',
        run_model_statement: 'Complete all mandatory fields below to run model.',
        decision: 'Decision Making',
        lead_days: 'What is the current product lead time from supplier?',
        days: 'DAYS',
        lead_days_msg: 'Please enter the product lead days.',
        replenish_days: 'How long is the replenishment cycle in the hospital?',
        min_days: 'MIN DAYS',
        max_days: 'MAX DAYS',
        replenish_min_days: 'Please enter the min days.',
        replenish_max_days: 'Please enter the max days.',
        suture_hub_msg: 'We plan to deploy Suture Hubs with:',
        suture_hub_msg1: 'A custom generated planogram',
        suture_hub_msg2: 'A system generated planogram',
        suture_hub_err_msg: 'Please choose the suture hub values.',
        loading: 'Loading...',
        uploading: 'Uploading',
        upload_new_file: 'Upload new file',
        upload_validate_new_file: 'Upload and validate new file',
        configurator_tool: 'Configurator tool',
        upload_file: 'Upload file',
        uploaded_file: 'Uploaded file',
        provide_initial_data: 'Provide initial configuration data',
        hospital_msg: 'Hospital provides necessary product and usage data to run the configurator.',
        summary: 'SUMMARY',
        coverage: 'COVERAGE',
        kishok: 'KIOSK',
        shelf: 'SHELF',
        hub: 'HUB',
        config_tool_summary: 'Configurator summary',
        max_hub: 'What is the maximum number of hubs?',
        hub_days_msg: 'Please enter the maximum hubs.',
        pls_upload_new_file: 'Please upload a .xlsx file.',
        pls_upload_valid_file: 'Please check data structure and upload a .xlsx file.',
        go_back: 'Go back',
        see_prev_model: 'See previous model results',
        could_not_run_configurator: 'Could not run configurator. Please try again later',
        run_failed_configurator: 'Run failed!'
    }
};
