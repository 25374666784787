import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';
import {MaterialsManagementTileFilterComponent} from 'src/app/core/dashboard/materials-management/materials-management-tile-filter/materials-management-tile-filter.component'
import {MaterialsManagementTileMagnifierComponent} from 'src/app/core/dashboard/materials-management/materials-management-tile-magnifier/materials-management-tile-magnifier.component'

import { AdamConf } from '@app/app.config';
import {
  FilterStateConfig,
  MagnifiedViewModel,
  TileDataRequestParams,
  TileDataSeriesCollection,
  TileResponseData
} from '../../models/materials-management-dashboard.model';
import { MaterialsManagementService } from '../materials-management.service';
import { PieChartDataModel } from '@app/shared/models/pie-chart.model';
import { LoggerService } from '@app/core/logger.service';
import { AuthService } from '@app/auth/auth.service';
import { LowerCasePipe } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { FormsModule } from '@angular/forms';

import { PieChartComponent } from 'src/app/shared/components/pie-chart/pie-chart.component';

@Component({
  selector: 'app-core-dashboard-materials-management-inventory-tile',
  templateUrl: './inventory-tile.component.html',
  styleUrls: ['./inventory-tile.component.scss'],
  standalone: true,
  imports: [PieChartComponent, LowerCasePipe, CommonModule,MaterialsManagementTileFilterComponent, DialogModule, FormsModule,MaterialsManagementTileMagnifierComponent]
})
export class InventoryTileComponent implements OnInit, OnDestroy {

  public adamLabels = AdamConf;
  public selectedOptions: string[] = [];
  private inventoryFilterSubscription$: Subscription;
  private inventoryStackCountDataSubscription$: Subscription;
  private inventoryValueDataSubscription$: Subscription;
  public inventoryStackData: TileResponseData;
  public inventoryValueData: TileResponseData;
  public valuePieChartData: PieChartDataModel[];
  public stackCountPieChartData: PieChartDataModel[];
  public isDisplayFilter = false;
  public filterConfig: FilterStateConfig;
  public ticker$: Subscription;
  @Input() showMagnifiedViewPopupData: MagnifiedViewModel;
  @Output() showMagnifiedPopup = new EventEmitter<MagnifiedViewModel>();

  constructor(
    private readonly materialManagementService: MaterialsManagementService,
    private readonly router: Router,
    private readonly logger: LoggerService,
    private readonly authService: AuthService,
    private readonly cdr: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    console.log('ngOnInit called');
    this.loadInventoryTileData();
    this.setAutoRefresh();
  }

  private loadInventoryTileData(): void {
    this.resetInventorySubscriptions();
    this.inventoryFilterSubscription$ = this.materialManagementService.inventoryTileFilterState$.subscribe((filterState) => {
      if (filterState) {
        let tileDataRequestParams: TileDataRequestParams;

        if (this.inventoryStackCountDataSubscription$) {
          this.inventoryStackCountDataSubscription$.unsubscribe();
        }
        tileDataRequestParams = {
          tileName: this.adamLabels.materialManagement_module.inventoryStackCountTileName,
          tileFilters: filterState
        };
        if (tileDataRequestParams && tileDataRequestParams.tileFilters.length > 0) {
          const selectedFilters = tileDataRequestParams.tileFilters.filter(item =>
            item.selectedValues && item.selectedValues.length > 0);
          this.selectedOptions = [];
          selectedFilters.forEach(item => {
            if (item.selectedValues.length > 0) {
              item.selectedValues.forEach(ele => this.selectedOptions.push(ele));
            }
          });
          if (this.selectedOptions && this.selectedOptions.length === 0) {
            this.selectedOptions.push('All Devices');
          } else if (this.selectedOptions.length === tileDataRequestParams.tileFilters[0].values.length) {
            this.selectedOptions = [];
            this.selectedOptions.push('All Devices');
          }
        }
        this.inventoryStackCountDataSubscription$ =
          this.materialManagementService.getTileData(tileDataRequestParams)
            .subscribe((response) => {
              if (response.responseCode === '2001' && response.responseData) {
                this.inventoryStackData = response.responseData;
                this.stackCountPieChartData = this.getPieChartData(this.inventoryStackData.dataSeriesCollection);
                this.cdr.detectChanges(); // Manually trigger change detection
              } else {
                this.logger.log('Invalid Tile Data response received for Inventory Stack');
              }
            }, (error) => {
              this.logger.log('Invalid Tile Data response received for Inventory Stack');
            });

        if (this.inventoryValueDataSubscription$) {
          this.inventoryValueDataSubscription$.unsubscribe();
        }
        tileDataRequestParams = {
          tileName: this.adamLabels.materialManagement_module.inventoryValueTileName,
          tileFilters: filterState
        };
        this.inventoryValueDataSubscription$ =
          this.materialManagementService.getTileData(tileDataRequestParams)
            .subscribe((response) => {
              if (response.responseCode === '2001') {
                this.inventoryValueData = response.responseData;
                this.valuePieChartData = this.getPieChartData(this.inventoryValueData.dataSeriesCollection);
                this.cdr.detectChanges(); // Manually trigger change detection
              } else {
                this.logger.log('Invalid Tile Data response received for Inventory Value');
              }
            }, (error) => {
              this.logger.log('Invalid Tile Data response received for Inventory Value');
            });

      }
    });
  }

  private setAutoRefresh(): void {
    const currentUser = this.authService.getCurrentUser();
    if (currentUser && currentUser.isAutoRefreshEnabled === true && this.showMagnifiedViewPopupData.isMagnifiedView !== true) {
      const delayToStartTimer = currentUser.autoRefreshTimeInterval || 10000; // Default to 10000ms if undefined
      const timerInterval = delayToStartTimer;
      const source = timer(delayToStartTimer, timerInterval);
      this.ticker$ = source.subscribe((val) => {
        this.logger.log('Refreshing Inventory tile data');
        this.loadInventoryTileData();
      });
    }
  }

  public displayFilter(): void {
    this.isDisplayFilter = true;
    console.log('aaaa')
    this.filterConfig = {
      isDisplayFilter: this.isDisplayFilter,
      
      tile: this.adamLabels.materialManagement_module.inventoryTileName
    };
    console.log('isDisplayFilter:', this.isDisplayFilter);

    this.materialManagementService.setFilterPopupState(this.filterConfig);
    this.showMagnifiedViewPopupData.isMagnifiedView = false;
    console.log('bbbb')
  }

  public showMagnifiedView(): void {
    this.showMagnifiedViewPopupData.isMagnifiedView = (this.showMagnifiedViewPopupData.isMagnifiedView === true) ? false : true;
    this.showMagnifiedViewPopupData.tile = (this.showMagnifiedViewPopupData.isMagnifiedView === true) ?
      this.adamLabels.materialManagement_module.inventoryTileName : '';
    this.showMagnifiedPopup.emit(this.showMagnifiedViewPopupData);
  }

  private getPieChartData(dataSeriesCollection: TileDataSeriesCollection[]): PieChartDataModel[] {
    let pieChartData: PieChartDataModel[];
    pieChartData = [];
    dataSeriesCollection.forEach((data) => {
      const derivedColor = this.getLegendColor(data.legend);
      pieChartData.push({
        value: data.dataPoints[0].value,
        color: derivedColor,
        label: String(data.dataPoints[0].value),
        tooltipData: data.dataPoints[0].toolTipInfo
      });
    });
    return pieChartData;
  }

  private getLegendColor(legendName: string): string {
    const legendColorMap = [
      { legend: 'High', color: '#1B294F' },
      { legend: 'Low', color: '#888b8d' },
      { legend: 'Medium', color: '#63666A' },
      { legend: 'Stock-Out', color: '#F56600' },
      { legend: 'Kiosk', color: '#63666A' },
      { legend: 'Smart Shelf', color: '#1B294F' },
      { legend: 'Hubs', color: '#888b8d' },
      { legend: 'SmartRoom', color: '#F56600'}
    ];
    const matchedLegend = legendColorMap.find(x => x.legend === legendName);
    return matchedLegend ? matchedLegend.color : '#000000'; // Default color if no match is found
  }

  public navigateToInventoryLevels(): void {
    this.router.navigate(['/control-tower/inventory-level']);
  }

  private resetInventorySubscriptions(): void {
    if (this.inventoryFilterSubscription$) {
      this.inventoryFilterSubscription$.unsubscribe();
    }
    if (this.inventoryStackCountDataSubscription$) {
      this.inventoryStackCountDataSubscription$.unsubscribe();
    }
    if (this.inventoryValueDataSubscription$) {
      this.inventoryValueDataSubscription$.unsubscribe();
    }
  }

  public ngOnDestroy(): void {
    console.log('ngOnDestroy called');
    this.resetInventorySubscriptions();
    if (this.ticker$) {
      this.ticker$.unsubscribe();
    }
  }
}
