import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from '../core/base.service';
import { StorageService } from '../core/storage.service';

@Injectable({
  providedIn: 'root'
})
export class OpsAuthService {

  private _currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private baseService: BaseService, private storageService : StorageService) { 
    const opsUser = this.storageService.getItem('opsUser');
    this._currentUserSubject = new BehaviorSubject<any>(opsUser ? JSON.parse(opsUser) : null);
    this.currentUser = this._currentUserSubject.asObservable();
  }

  getOpsCurrentUser () {
    return this._currentUserSubject.value;
  }

  authorize(param: any){
    return this.baseService.postViaObjectParam('user-management/api/v1/ops/token', param);
  }

  setSession(value: { [x: string]: any; }){
    return new Promise(resolve => {
      this.storageService.setItem("opsUser", JSON.stringify(value));
      this.setExpiry(value['expires_in']);
      this._currentUserSubject.next(value);
      resolve(true);
    });

  } 

  setSessionValues(value: { [x: string]: any; }) {
    this.storageService.setItem("opsUser", JSON.stringify(value));
    this.setExpiry(value['expires_in']);
    this._currentUserSubject.next(value);
  } 

  resetSession() {
    this.storageService.removeItem('opsUser');
    this.storageService.removeItem('opsExpTime');
    this._currentUserSubject.next('');
  }
  getRefreshToken() {
    const userSession = this.getOpsCurrentUser();
    let params = {
      "grantType": "refresh_token",
      "refreshToken": userSession['refresh_token']
    }
    return this.baseService.postViaObjectParam('user-management/api/v1/ops/token', params);
  }

  getExpiry() {
    let expiryTime = this.storageService.getItem("opsExpTime");
    if(expiryTime){
      return expiryTime;
    }
    else {
      return null;
    }
  }

  setExpiry(time: number) {
    let curTime = new Date();
    let upTime = curTime.setSeconds(curTime.getSeconds() + time);
    this.storageService.setItem("opsExpTime", upTime.toString());
  }

}
