import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { StorageService } from '../../app/core/storage.service';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  url: string = '';
  baseUrl: string = '';
  hospital: any;

  constructor(private http: HttpClient,
    private storageService: StorageService,
  ) { }

  setUrl(relativeUrl: string) {
    if (location.hostname === 'localhost') {
      this.baseUrl = environment.baseUrl;
    } else {
      this.baseUrl = '/aita-'; // this will take relative path for all api calls
    }
  }

  public getViaParam(relativeUrl: any, queryParams: any, optionalHeader = null): Observable<any> {
    let params = new HttpParams();
    if (queryParams) {
      for (let param in queryParams) {
        params = params.set(param, queryParams[param]);
      }
    }
    // Set any specific headers
    let headers = {};
    headers['Content-Type'] = 'application/json';
    // headers['user-navigation'] = this.storageService.getSessionItem('user-navigation');
    if (optionalHeader) {
      headers = Object.assign(headers, optionalHeader);
    }
    const httpOptions = {
      headers: new HttpHeaders(headers),
      params: params
    };
    this.setUrl(relativeUrl);
    const url = (relativeUrl) ? this.baseUrl + relativeUrl : this.baseUrl;
    return this.http.get(url, httpOptions);
  }

  getJsonFile(path: string): Observable<any> {
    this.setUrl(path);
    return this.http.get(path);
  }

  getImageURL(path: string) {
    return this.baseUrl + path;
  }

  getImage(path: string): Observable<any> {
    this.setUrl(path);
    return this.http.get(this.baseUrl + path, { responseType: 'blob' });
  }

  public saveData(relativeUrl: string, queryParams: any) {
    let params = new HttpParams();
    if (queryParams) {
      for (let param in queryParams) {
        params = params.set(param, queryParams[param]);
      }
    }
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'user-navigation': this.storageService.getSessionItem('user-navigation')
    });
    let options = { headers: headers };
    this.setUrl(relativeUrl);
    return this.http.post(this.baseUrl + relativeUrl, queryParams, options);
  }

  public postViaObjectParam(relativeUrl: string, querystring: any, optionalHeader: any = {}): Observable<any> {
    let params = JSON.stringify(querystring);
    let headers = {
      'Content-Type': 'application/json',
      'withCredentials': 'true',
      // 'user-navigation': this.storageService.getSessionItem('user-navigation')
    };
    if (optionalHeader) {
      headers = Object.assign(headers, optionalHeader);
    }
    const httpOptions = {
      headers: new HttpHeaders(headers)
    };
    this.setUrl(relativeUrl);
    return this.http.post(this.baseUrl + relativeUrl, params, httpOptions);
  }

  private getHeadersAsObject(headers: HttpHeaders): { [key: string]: string } {
    const headersObj: { [key: string]: string } = {};
    headers.keys().forEach(key => {
      headersObj[key] = headers.get(key) as string;
    });
    return headersObj;
  }

  public postViaHttpParam(relativeUrl: string, querystring: any, optionalHeader? : { [key: string]: string } | null): Observable<any> {
    let formData = new FormData();
    for (let param in querystring) {
      formData.append(param, querystring[param]);
    }
    let headers = {
      // 'user-navigation': this.storageService.getSessionItem('user-navigation')
    };
    if (optionalHeader) {
      headers = Object.assign(headers, optionalHeader);
    }
    const httpOptions = {
      headers: new HttpHeaders(headers)
    };
    this.setUrl(relativeUrl);
    return this.http.post(this.baseUrl + relativeUrl, formData, httpOptions);
  }

  public postCsvViaObjectParam(relativeUrl: string, obj: any): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'text/csv; charset=UTF-8',
      'user-navigation': this.storageService.getSessionItem('user-navigation')
    });
    let options = { headers: headers };
    this.setUrl(relativeUrl);
    return this.http.post(this.baseUrl + relativeUrl, obj, options);
  }

  public putViaHttpParam(relativeUrl: string, querystring: any): Observable<any> {
    let formData = new FormData();
    for (const param in querystring) {
      formData.append(param, querystring[param]);
    }
    let headers = {
      'user-navigation': this.storageService.getSessionItem('user-navigation')
    };
    const httpOptions = {
      headers: new HttpHeaders(headers)
    };
    this.setUrl(relativeUrl);
    return this.http.put(this.baseUrl + relativeUrl, formData, httpOptions);
  }

  // To upload file
  public uploadFile(relativeUrl: string, queryParams: any, file: File): Observable<any> {
    let formData = new FormData();
    formData.append('data-file', file, file.name);
    let headers = new HttpHeaders({
      'Content-Type': 'text/;pplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Accept': 'text/csv',
      'user-navigation': this.storageService.getSessionItem('user-navigation')
    });

    // Queryparams
    let params = new HttpParams();
    if (queryParams) {
      for (let param in queryParams) {
        params = params.set(param, queryParams[param]);
      }
    }
    let options = {
      headers: headers,
      params: params
    };
    this.setUrl(relativeUrl);
    return this.http.post(this.baseUrl + relativeUrl, formData, options)
      .pipe(
        tap(),
      );
  }

  public sheduleUpload(url: string, obj: any, optionalHeader = null) {
    this.setUrl(url);
    let headers = {
      'user-navigation': this.storageService.getSessionItem('user-navigation')
    };
    if (optionalHeader) {
      headers = Object.assign(headers, optionalHeader);
    }
    return this.http.post(this.baseUrl + url, obj, {
      reportProgress: true,
      observe: 'events',
      headers: headers
    });
    // .map(data => data);
  }

  public deviceProvision(url: string, obj: any, facilityId: any) {
    const httpOptions: {
      headers: HttpHeaders,
      observe: 'response',
      reportProgress?: boolean,
      responseType: 'blob',
      withCredentials?: boolean
    } = {
      headers: new HttpHeaders({
        'Accept': '*/*',
        'tenant-id': facilityId + '',
        'user-navigation': this.storageService.getSessionItem('user-navigation')
      }),
      observe: 'response',
      responseType: 'blob'
    };
    this.setUrl(url);

    return this.http.post(this.baseUrl + url, obj, httpOptions).pipe(
      tap(
        res => {
          return res;
        }
      ),
    );
  }

  public downloadFile(relativeUrl: any, queryParams: any): Observable<any> {
    let params = new HttpParams();
    if (queryParams) {
      for (let param in queryParams) {
        params = params.set(param, queryParams[param]);
      }
    }
    const httpOptions: {
      headers: HttpHeaders,
      observe: 'response',
      params: HttpParams,
      reportProgress?: boolean,
      responseType: 'blob',
      withCredentials?: boolean
    } = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Accept': 'text/csv',
          'user-navigation': this.storageService.getSessionItem('user-navigation')
        }),
      observe: 'response',
      params: params,
      responseType: 'blob'

    };
    this.setUrl(relativeUrl);
    const url = (relativeUrl) ? this.baseUrl + relativeUrl : this.baseUrl;
    return this.http.get(url, httpOptions)
      .pipe(
        tap(
          res => {
            return res;
          }
        ),
      );
  }

  exportFileViaPost(relativeUrl: string, requestParams: any) {
    let headers = new HttpHeaders({
      'user-navigation': this.storageService.getSessionItem('user-navigation')
    });
    this.setUrl(relativeUrl);
    const url = (relativeUrl) ? this.baseUrl + relativeUrl : this.baseUrl;
    return this.http.post<HttpResponse<Blob>>(
      url,
      requestParams,
      {
        responseType: 'blob' as 'json',
        reportProgress: true,
        observe: 'events',
        headers: headers
      }
    );
  }

  exportFileViaGet(relativeUrl: string, queryParams: any, optionalHeader = null) {
    let headerObj: { [key: string]: string } = {};
    headerObj['user-navigation'] = this.storageService.getSessionItem('user-navigation');

    let queryString = '';
    if (optionalHeader) {
      headerObj = Object.assign(headerObj, optionalHeader);
    }
    let headers = new HttpHeaders(headerObj);
    let options = { headers: headers };
    if (queryParams) {
      let params = new URLSearchParams();
      for (const key of queryParams) {
        params.set(key, queryParams[key]);
      }
      queryString += '?' + params.toString();
    }
    this.setUrl(relativeUrl);
    const url = (relativeUrl) ? this.baseUrl + relativeUrl : this.baseUrl;
    return this.http.get<HttpResponse<Blob>>(
      url + queryString,
      {
        headers: headers,
        responseType: 'blob' as 'json',
        reportProgress: true,
        observe: 'events'
      }
    );
  }

}

