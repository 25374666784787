import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-customer-support',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './customer-support.component.html',
  styleUrls: ['./customer-support.component.scss']

})
export class CustomerSupportComponent {
  email: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {
    this.email = this.sanitizer.bypassSecurityTrustHtml('<a rel="noopener noreferrer" href="mailto:AITA@its.jnj.com" data-mce-href="mailto:AITA@its.jnj.com">AITA@its.jnj.com</a>');
  }
}
