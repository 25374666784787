import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-legal-notice',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './legal-notice.component.html',
  styleUrls: ['./legal-notice.component.scss']
})
export class LegalNoticeComponent {
  email: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {
    this.email = this.sanitizer.bypassSecurityTrustHtml('<a rel="noopener noreferrer" href="mailto:Jnjmedicaldevices@its.jnj.com">Jnjmedicaldevices@its.jnj.com</a>');
  }
}
