import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PDialogService {

  public visibility: BehaviorSubject<boolean>;
  public dialog: BehaviorSubject<object>;

  constructor() {
    this.visibility = new BehaviorSubject(false);
    this.dialog = new BehaviorSubject({});
  }

  public show(error: any): void {
    this.dialog.next(error);
    this.visibility.next(true);
  }

  public hide(): void {
    this.visibility.next(false);
  }
}
