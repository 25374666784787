<div  *ngIf="showDialog">
    <p-dialog [(visible)]="showDialog"  showHeader="false" [modal]="true" [responsive]="true" [style]="{width: '460px'}" [minY]="70" 
    [baseZIndex]="10000"  header="Application Error" styleClass="customDialog" >
    <div style="padding:30px 45px 30px 40px">
       Status : {{ errorDialog.status  }} {{ errorDialog.statusText  }} <br>
       Url : {{ errorDialog.url }}<br>
       Details : {{ errorDialog.error.message }}<br>
       </div>
       <p-footer>
        <button class="button-primary-large" style="width: 146px;" (click)="display()" >Go to Dashboard</button>
    </p-footer>
    </p-dialog>
    
</div>

