import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, finalize, mergeMap, retry } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { PDialogService } from '../util/p-dialog.service';
import { OpsAuthService } from '../ops/ops-auth.service';
import { LoggerService } from './logger.service';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private appMode: any = '';
  private maxAttempts = 1;
  private scaleDuration = 1000;
  private noRetryResponseCodes = [404, 401, 400];

  constructor(
    private readonly pDialogService: PDialogService,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly opsAuthService: OpsAuthService,
    private readonly logger: LoggerService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.appMode = (location.href.indexOf('ops') !== -1) ? 'ops' : 'aita';

    return next.handle(request)
      .pipe(
        retry({
          count: this.maxAttempts,
          delay: (error, retryAttempt) => {
            if (this.noRetryResponseCodes.includes(error.status)) {
              return throwError(() => error);
            }
            return timer(retryAttempt * this.scaleDuration);
          }
        }),
        catchError((error: HttpErrorResponse): Observable<any> => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            errorMessage = `Error: ${error.error.message}`;
          } else if (error.status === 401) {
            if (this.appMode !== 'ops') {
              this.authService.resetSession();
              this.router.navigate(['home']);
            } else {
              this.opsAuthService.resetSession();
            }
            return throwError(() => new Error(error.message));
          } else {
            if (error.status === 400 && error.error &&
              (error.error.error === 'invalid_grant' || error.error.error === 'duplicatekey_exception')) {
              this.logger.error('Token error: ' + error.message);
              return throwError(() => new Error(error.message));
            } else {
              errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
              this.pDialogService.show(error);
              return throwError(() => new Error(errorMessage));
            }
          }
          return throwError(() => new Error(errorMessage));
        })
      );
  }
}
